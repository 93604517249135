import { ValidateCouponInput, ValidateCouponOutput } from './coupon.model';
import { ICouponRepository } from './coupon.repository';

export class CouponService {
  constructor(private couponRepo: ICouponRepository) {
    this.couponRepo = couponRepo;
  }

  async validate(data: ValidateCouponInput): Promise<ValidateCouponOutput> {
    return this.couponRepo.validate(data);
  }
}
