import type { IHttpClient } from '../../AxiosApiClient';
import { IRepository } from '../base.repository';
import { ValidateCouponResponseDTO, ValidateCouponRequestDTO } from './coupon.dto';

export interface ICouponRepository extends IRepository {
  validate(data: ValidateCouponRequestDTO): Promise<ValidateCouponResponseDTO>;
}

export class CouponRepository implements ICouponRepository {
  private endpoint: string = '/coupons';

  constructor(private httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  async validate(data: ValidateCouponRequestDTO): Promise<ValidateCouponResponseDTO> {
    return this.httpClient.put<ValidateCouponRequestDTO, ValidateCouponResponseDTO>(`${this.endpoint}`, data);
  }
}
