import React, { memo } from 'react';
import ProductCard from '../ProductCard/ProductCard';
import { Product } from '../../../../domain/product/product.model';
import { MosaicCardContainer, MosaicContainer } from './Mosaic.styles';
import stones_image from '../../../../assets/images/stones_background.webp';

export interface Props {
  data: Array<Product>;
}

const Mosaic: React.FC<Props> = ({ data }) => (
  <MosaicContainer url={stones_image}>
    {data?.slice(0, 8).map((product, i) => (
      <MosaicCardContainer key={i}>
        <ProductCard key={i} product={product} />
      </MosaicCardContainer>
    ))}
  </MosaicContainer>
);

export default memo(Mosaic);
