import { useState } from 'react';
import {
  Button,
  Block,
  CustomLink,
  Quantity,
  ShareWith,
  Text,
  Tooltip,
  SizePicker,
} from '../../../../../../components';
import { Product } from '../../../../../../domain/product/product.model';
import { InfoSectionContainer, LogoImage, BottomContainer, MiddleContainer } from './InfoSection.styles';
import visa from '../../../../../../assets/images/visa.svg';
import mastercard from '../../../../../../assets/images/mastercard.svg';
import paypal from '../../../../../../assets/images/paypal.svg';
import { useCart } from '../../../../../../hooks';
import { CartUpdateAction } from '../../../../../../domain/cart/cart.dtos';
import { ShareIcons } from '../../../../../../components/ShareWith/ShareWith.models';
import { useHistory } from 'react-router';
import { routes } from '../../../../../../routes';
import { environment } from '../../../../../../constants';
import { NumberHelper, StringHelper } from '../../../../../../helpers';

interface Props {
  product: Product;
}

const InfoSection: React.FC<Props> = ({ product }) => {
  const { updateCart } = useCart();
  const [quantity, setQuantity] = useState<number>(1);
  const [size, setSize] = useState(product?.sizes[0] ?? '');
  const title = product?.name;
  const description = product?.description;
  const price = product?.price;
  const categories = product?.categories;
  const productSizes = product?.sizes;
  const MAX = product?.stock;
  const history = useHistory();
  const handlerChangeQuantity = (type: 'add' | 'remove') => {
    if (type === 'add' && quantity < MAX) {
      setQuantity((lastVal) => lastVal + 1);
      return;
    }
    if (type === 'remove' && quantity > 1) {
      setQuantity((lastVal) => lastVal - 1);
      return;
    }
  };

  const handleSelectSize = (size: string) => {
    setSize(size);
  }
  // TODO: change select condition to opposite when test are ready.
  const isMobile = window.innerWidth <= 800;
  const isVisibleTooltip = quantity >= MAX;
  const shareIcons: Array<ShareIcons> = ['facebook', 'twitter', 'whatsapp'];
  const filteredCategories = categories.filter((item) => item !== 'tienda' && item !== 'mosaico');
  const categoriesToLink = filteredCategories?.length > 3 ? filteredCategories.slice(0, 3) : filteredCategories;
  const categoriesLinks = categoriesToLink.map((category, idx) => (
    <CustomLink
      key={idx}
      fontSize="l"
      hoverColor="darkSilver"
      weight="medium"
      to={`${routes.STORE_PRODUCTS}/${StringHelper.formateEmptySpacesToUrl(category)}`}
      mr="m">
      {category.charAt(0).toUpperCase() + category.slice(1) ?? ''}
    </CustomLink>
  ));

  return (
    <InfoSectionContainer>
      <Block width="100%" display="flex" justify="space-between">
        <Block display="flex">{categoriesLinks}</Block>
        <Block display="flex" align="center" justify="flex-end">
          {shareIcons.map((icon, i) => (
            <Block mr="m" key={i}>
              <ShareWith size="xs" icon={icon} url={`${environment.ORIGIN}${history?.location?.pathname}`} />
            </Block>
          ))}
        </Block>
      </Block>
      <Block mt="s">
        <Text as="h2" fontsize="s" fontFamily="base" isUppercase mb="m" weight="medium">
          {title}
        </Text>
        <Text as="p" fontsize="l" fontFamily="base">
          {description}
        </Text>
      </Block>
      <MiddleContainer>
        <Block width="100%" display="flex" justify="space-between" align="center" mb="m">
          <Text as="h3" weight="semiBold">
            {NumberHelper.formatAmount(price, 2)}€
          </Text>
          <Block>
            {isVisibleTooltip ? (
              <Tooltip content="No disponemos de más stock para este producto" position={isMobile ? 'top' : 'right'}>
                <Quantity quantity={quantity} handlerChangeQuantity={handlerChangeQuantity} />
              </Tooltip>
            ) : (
              <Quantity quantity={quantity} handlerChangeQuantity={handlerChangeQuantity} />
            )}
          </Block>
          <Block>
            {productSizes?.length && (
              <SizePicker
                sizes={productSizes}
                selectedSize={size}
                handleSelectSize={handleSelectSize}
              />
            )}
          </Block>
        </Block>
        <Block width="100%" display="flex">
          <Button
            onClick={() => updateCart({product, qty: quantity, action: CartUpdateAction.ADD, size})}
            text=" Agregar al carrito"
            variant="default"
            fullWidth
            color="earth"
            size="m"
            id={`info_section_add_to_cart_button`}
          />
        </Block>
      </MiddleContainer>
      <BottomContainer>
        <LogoImage alt="visa_logo" src={visa} />
        <LogoImage alt="mastercard_logo" src={mastercard} />
        <LogoImage alt="paypal_logo" src={paypal} />
      </BottomContainer>
    </InfoSectionContainer>
  );
};

export default InfoSection;
