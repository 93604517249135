import { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import ErrorBoundary from '../../components/errorBoundary/ErrorBoundary';
import { routes } from '../../routes';

const Register = lazy(() => import('./register'));
const SingIn = lazy(() => import('./singIn/SingInPage'));
const ForgotPassword = lazy(() => import('./forgotPassword/ForgotPasswordPage'));
const ConfirmPasswordRecovery = lazy(() => import('./confirmPasswordRecovery'));
const ChangePasswordPage = lazy(() => import('./changePassword'));
const DropOut = lazy(() => import('./dropOut/DropOutPage'));

const UserRoutes = function () {
  return (
    <ErrorBoundary>
      <Switch>
        <Route exact path={`${routes.NEW_PASSWORD}/:token`}>
          <ConfirmPasswordRecovery />
        </Route>
        <Route exact path={`${routes.REGISTER}`}>
          <Register />
        </Route>
        <Route exact path={`${routes.LOGIN}`}>
          <SingIn />
        </Route>
        <Route exact path={`${routes.CHANGE_PASSWORD}`}>
          <ChangePasswordPage />
        </Route>
        <Route exact path={`${routes.FORGOT_PASSWORD}`}>
          <ForgotPassword />
        </Route>
        <Route exact path={`${routes.DROP_OUT}`}>
          <DropOut />
        </Route>
      </Switch>
    </ErrorBoundary>
  );
};

export default UserRoutes;
