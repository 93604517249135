import styled, { css } from 'styled-components';
import { HorizontalAlignment, InputLabelProps, LabelPosition, VerticalAlignment } from './InputLabel';

const horizontalAlignmentValues = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
};

const verticalAlignmentValues = {
  base: 'baseline',
  center: 'center',
  end: 'end',
};

const setMarginByPosition = (position?: LabelPosition) => {
  switch (position) {
    case 'up':
      return css`
        margin-bottom: 0.5rem;
      `;
    case 'right':
      return css`
        margin-left: 0.5rem;
      `;
    case 'bottom':
      return css`
        margin-up: 0.5rem;
      `;
    case 'left':
      return css`
        margin-right: 0.5rem;
      `;
    default:
      return css``;
  }
};

export const InputLabelWrapper = styled.div<InputLabelProps>`
  display: flex;
  align-items: ${(p) => verticalAlignmentValues[(p.verticalAlignment as VerticalAlignment) ?? 'center']};
  justify-content: ${(p) => horizontalAlignmentValues[(p.horizontalAlignment as HorizontalAlignment) ?? 'center']};
  ${(p) => setMarginByPosition(p.position)};
`;

export const InputLabelContent = styled.span`
  font-family: ${(p) => p.theme.fontFamily.baseLabel};
  color: ${(p) => p.theme.color.black};
  font-size: ${(p) => p.theme.fontSize.m};
  letter-spacing: 0.2rem;
`;
