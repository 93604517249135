import { Anchor, Block, CustomLink, PageContainer, Text } from '../../components';
import { routes } from '../../routes';

const InvalidToken = function () {
  return (
    <PageContainer pageTitle="Token invalido">
      <Block display="flex" direction="column" justify="center" align="flex-start" pr="m" pl="m">
        <Text color="black" fontsize="m" mb="s">
          El token que ha recibido a caducado, este tiene una duracion de 1 hora.
        </Text>
        <Text color="black" fontsize="m" mb="s">
          Por favor vuelva a registrarse en:
          <CustomLink to={routes.REGISTER}>Registrarse</CustomLink>
        </Text>
        <Text color="black" fontsize="m" mb="s">
          Si el probleman persiste contacte con nosotros a:
          <br />
          <Anchor href="info@sinaglife.com">info@sinaglife.com</Anchor>
        </Text>
      </Block>
    </PageContainer>
  );
};

export default InvalidToken;
