import { lazy } from 'react';
import { Route, Switch } from 'react-router';
import ErrorBoundary from '../../components/errorBoundary/ErrorBoundary';
import { routes } from '../../routes';

const BlogPage = lazy(() => import('./BlogPage'));
const BlogPostPage = lazy(() => import('./post'));
const BlogFromWordPage = lazy(() => import('./BlogFromWord'));

const BlogRoutes = () => (
  <ErrorBoundary>
    <Switch>
      <Route path={`${routes.BLOG}/:id`}>
        <BlogPostPage />
      </Route>
      <Route path={routes.BLOG}>
        <BlogPage />
      </Route>
      <Route path={`${routes.BLOG}-prueba`}>
        <BlogFromWordPage />
      </Route>
    </Switch>
  </ErrorBoundary>
);

export default BlogRoutes;
