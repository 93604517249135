import { Blog } from './blog.model';
import { IBlogRepository } from './blog.repository';

export class BlogService {
  private blogRepository: IBlogRepository;

  constructor(blogRepository: IBlogRepository) {
    this.blogRepository = blogRepository;
  }

  async getMainData(): Promise<Array<Blog>> {
    return this.blogRepository.getMany();
  }

  async getBlogDetail(id: string): Promise<Blog | null> {
    return this.blogRepository.get(id);
  }
}
