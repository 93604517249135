import { createSlice } from '@reduxjs/toolkit';
import { Product } from '../../domain/product/product.model';
import { getProducts } from './products.thunks';

export interface ProductState {
  isLoading: boolean;
  products: Array<Product>;
  error: string;
}

const initialState: ProductState = {
  isLoading: false,
  products: [],
  error: '',
};

export const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getProducts.pending, (state, _) => {
      state.isLoading = true;
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = action.payload;
    });
    builder.addCase(getProducts.rejected, (state, _) => {
      state.isLoading = false;
    });
  },
});

export default productSlice.reducer;
