export default function CloseMenu() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <rect />
      <path d="M8 11H40" stroke="black" />
      <path d="M8 24H40" stroke="black" />
      <path d="M8 37H40" stroke="black" />
      <path d="M13.6567 29.6569L7.99988 24L13.6567 18.3431" />
    </svg>
  );
}
