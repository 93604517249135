import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Block, Button, Form, FormCard, Input, Spinner, Text } from '../../../components';
import { DispatchAction, DefaultState } from '../../../redux/store';
import { confirmPasswordRecovery } from '../../../redux/user/user.thunks';
import { UserState } from '../../../redux/user/user.slice';
import { selectUserState } from '../../../redux/user/user.selectors';
import { isValidEmail, isValidPassword } from '../../../modules/user/domain/User';

export interface UpdatePasswordFormikState {
  email: string;
  password: string;
  confirmPassword: string;
}

const ConfirmPasswordRecoveryPage = () => {
  const { isLoading } = useSelector<DefaultState, UserState>(selectUserState);
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const dispatch = useDispatch<DispatchAction>();

  useEffect(() => {
    if (!token) {
      history.push('/');
    }
  }, [token, history]);

  const initialState = {
    email: '',
    password: '',
    confirmPassword: '',
  };

  function validator(values: UpdatePasswordFormikState) {
    const errors: Partial<UpdatePasswordFormikState> = {};

    if (!values.email) {
      errors.email = 'Email requerido';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Email invalido';
    }

    if (!values.password) {
      errors.password = 'Campo requerido';
    }
    if (values.password.length < 5) {
      errors.password = 'Contraseña minimo 5 caracteres';
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = 'Campo requerido';
    }
    if (values.confirmPassword.length < 5) {
      errors.confirmPassword = 'Contraseña minimo 5 caracteres';
    }
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Las contraseñas deben coincidir';
    }

    return errors;
  }

  const formik = useFormik({
    initialValues: initialState,
    onSubmit: async (values) => {
      const data = {
        email: values.email,
        newPassword: values.password,
        token,
      };

      dispatch(confirmPasswordRecovery({ userData: data, formik }));
    },

    validate: (values: UpdatePasswordFormikState) => {
      const errors: Partial<UpdatePasswordFormikState> = {};
  
      if (!values.email) {
        errors.email = 'Email requerido';
      } else if (!isValidEmail(values.email)) {
        errors.email = 'Email invalido';
      }
      if (!values.password) {
        errors.password = 'Campo requerido';
      } else if (!isValidPassword(values.password)) {
        errors.password = 'Contraseña minimo 5 caracteres';
      }

      if (!values.confirmPassword) {
        errors.confirmPassword = 'Campo requerido';
      } else if (!isValidPassword(values.confirmPassword)) {
        errors.confirmPassword = 'Contraseña minimo 5 caracteres';
      }
      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Las contraseñas deben coincidir';
      }
  
      return errors;
    },
  });

  if (formik.isSubmitting || isLoading) {
    return <Spinner top="25%" />;
  }
  const isButtonDisabled = !formik.dirty || formik.isSubmitting || !formik.isValid;

  return (
    <Form
      onSubmit={formik.handleSubmit}
      display="flex"
      direction="column"
      justify="space-evenly"
      align="center"
      width="100%"
      pt="xl"
      pb="xl"
    >
      <FormCard>
        <Block width="100%" display="flex" direction="column" align="center" justify="center">
          <Block display="flex" width="100%" justify="center" pt="s" pb="s">
            <Text as="h3" weight="semiBold" fontsize="s" isUppercase>
              Nueva contraseña
            </Text>
          </Block>
          <Block display="flex" direction="column" width="100%" pt="s" pb="s">
            <Text as="h4" fontsize="s">
              Email
            </Text>
            <Input
              onChange={formik.handleChange}
              type="text"
              name="email"
              value={formik.values.email}
              variant="outlined"
              inputSize="m"
            />
            {formik.errors.email && (
              <Block display="flex" width="100%" justify="center">
                <Text fontSize="s" color="red">
                  {formik.errors.email as string}
                </Text>
              </Block>
            )}
          </Block>
          <Block display="flex" direction="column" width="100%" pt="s" pb="s">
            <Text as="h4" fontsize="s">
              Contraseña
            </Text>
            <Input
              onChange={formik.handleChange}
              type="text"
              name="password"
              value={formik.values.password}
              variant="outlined"
              inputSize="m"
            />
            {formik.errors.password && (
              <Block display="flex" width="100%" justify="center">
                <Text fontSize="s" color="red">
                  {formik.errors.password as string}
                </Text>
              </Block>
            )}
          </Block>
          <Block display="flex" direction="column" width="100%" pt="s" pb="s">
            <Text as="h4" fontsize="s">
              Confirmar Contraseña
            </Text>
            <Input
              onChange={formik.handleChange}
              type="text"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              variant="outlined"
              inputSize="m"
            />
            {formik.errors.confirmPassword && (
              <Block display="flex" width="100%" justify="center">
                <Text fontSize="s" color="red">
                  {formik.errors.confirmPassword as string}
                </Text>
              </Block>
            )}
          </Block>
          <Block display="flex" justify="center" mt="m" pt="s" pb="s" width="100%">
            <Button
              text="Enviar"
              type="submit"
              disabled={isButtonDisabled}
              variant="default"
              color="earth"
              size="m"
              fullWidth
              id="update_password_send"
            />
          </Block>
        </Block>
      </FormCard>
    </Form>
  );
};

export default ConfirmPasswordRecoveryPage;
