import styled, { css } from 'styled-components';
import { AvatarProps, AvatarImgProps } from './Avatar.models';
import { Size } from '../../theme';

const avatarSizes: { [key in Size]: number } = {
  xs: 20,
  s: 25,
  m: 40,
  l: 55,
};

const setSize = (size: Size) => {
  const selectedSize = avatarSizes[size];
  return css`
    width: ${selectedSize}px;
    height: ${selectedSize}px;
  `;
};

const borderRadius = {
  rounded: '20%',
  circular: '50%',
  square: '0',
};

export const AvatarContainer = styled.div<AvatarProps>`
  border-radius: ${(p) => (p.variant ? borderRadius[p.variant] : null)};
  ${(p) => setSize(p.size)};
  cursor: ${(p) => p.cursor && p.cursor};
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(p) => p.borderColor && `1px solid ${p.theme.color[p.borderColor]}`};
  ${(p) => p.customStyles && p.customStyles}
`;

export const AvatarImg = styled.img<AvatarImgProps>`
  background-image: url(${(p) => p.src});
  border-radius: ${(p) => (p.variant ? borderRadius[p.variant] : null)};
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
`;
