import { useState, useEffect } from 'react';
import { ProductCardContainer, ProductImage, StyledBlock, StyledProductName, StyledRegularPrice } from './ProductCard.styles';
import { Block, CustomLink, Text } from '..';
import { Product } from '../../domain/product/product.model';
import { Size } from '../../theme';
import { routes } from '../../routes';
import { NumberHelper, StringHelper } from '../../helpers';

interface Props {
  productData: Product;
  size?: Size;
  callBack?: () => void;
  category?: string;
}

const ProductCard: React.FC<Props> = ({ productData, size, category, callBack }) => {
  const [imgToShow, setImgToShow] = useState<string>('');
  const imgArray = productData?.images?.map((item) => item?.src);
  const altImg = productData?.images[0]?.alt;
  const formattedNameToUrl = StringHelper.formateEmptySpacesToUrl(productData?.name);
  const mainCategory = category ?? productData.categories[0];
  useEffect(() => {
    setImgToShow(productData?.images[0]?.src);
  }, [productData]);

  const getImgToShow = () => {
    if (imgArray.length > 1) {
      if (imgArray[1]) {
        if (imgToShow === imgArray[1]) {
          setImgToShow(imgArray[0]);
        } else {
          setImgToShow(imgArray[1]);
        }
      }
    }
  };
  const ITS_ON_SALE = productData?.salePrice && (productData?.salePrice < productData?.price);
  return (
    <CustomLink
      to={{
        pathname: `${routes.STORE_PRODUCTS}/${StringHelper.formateEmptySpacesToUrl(mainCategory)}/${formattedNameToUrl}`,
        state: productData,
      }}
      hoverColor="earth"
      onClick={callBack}
    >
      <ProductCardContainer size={size ?? 'l'}>
        <ProductImage
          size={size ?? 'l'}
          onMouseEnter={getImgToShow}
          onMouseLeave={getImgToShow}
          src={imgToShow}
          alt={altImg}
        />
        <StyledBlock size={size ?? 'l'} display="flex" direction="column" align="center" pt="m">
          <StyledProductName size={size ?? 'l'} isUppercase fontsize={size ?? 'm'} textAlign="center">
            {productData?.name}
          </StyledProductName>
          {
            ITS_ON_SALE ? (
            <Block display='flex' direction='column'>
              <StyledRegularPrice fontsize="s">{NumberHelper.formatAmount(productData?.price, 2)}€</StyledRegularPrice>
              <Text color='red' fontsize="m">{NumberHelper.formatAmount(productData?.salePrice, 2)}€</Text>
            </Block>
            ) : (
              <Text fontsize="m">{NumberHelper.formatAmount(productData?.price, 2)}€</Text>
            )
          }
        </StyledBlock>
      </ProductCardContainer>
    </CustomLink>
  );
};

export default ProductCard;
