import React from 'react';
import { Product } from '../../../../domain/product/product.model';
import Block from '../../../../components/Block';
import ImageComponent from '../../../../components/ImageComponent';
import Text from '../../../../components/Text';
import { ProductCardContainer } from './ProductCard.styles';
import Button from '../../../../components/Button';
import { useCart } from '../../../../hooks';
import { CartUpdateAction } from '../../../../domain/cart/cart.dtos';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { routes } from '../../../../routes';
import { NumberHelper, StringHelper } from '../../../../helpers';

export interface Props {
  product: Product;
}

const ProductCard: React.FC<Props> = ({ product }) => {
  const { images, name, price } = product;
  const { src } = images[0];
  const { alt } = images[0];
  const QUANTITY = 1;
  const { updateCart } = useCart();
  const { t } = useTranslation('home');
  const history = useHistory();

  const goToProductDetail = () => {
    const productCategory = product?.categories[0];
    history.push(`${routes.STORE_PRODUCTS}/${productCategory}/${StringHelper.formateEmptySpacesToUrl(name)}`, {
      state: product,
    });
  };

  return (
    <ProductCardContainer display="flex" direction="column" align="center" justify="space-between" bgColor="white">
      <Block width="100%" height="60%" pt="s" pr="s" pb="s" pl="s" onClick={goToProductDetail}>
        <ImageComponent src={src} alt={alt} />
      </Block>
      <Block width="100%" height="28%" pt="s" pr="s" pb="s" pl="s" display="flex" direction="column">
        <Text
          customStyles={{ maxHeight: '35px' }}
          color="black"
          fontFamily="base"
          textAlign="center"
          mb="s"
          fontsize="s"
        >
          {name}
        </Text>
        <Text color="black" fontFamily="base" weight="semiBold" fontsize="s" textAlign="center" mb="s">
          {NumberHelper.formatAmount(price, 2)}€
        </Text>
      </Block>
      <Block width="100%">
        <Button
          onClick={() => updateCart({product, qty: QUANTITY, action: CartUpdateAction.ADD})}
          text={t('mosaic.product.add_to_cart_btn') ?? 'Agregar al carrito'}
          variant="default"
          fullWidth
          fullHeight
          color="xLightSilver"
          size="m"
          id={`mosaic_product_card_add_to_cart_${product?.id}`}
        />
      </Block>
    </ProductCardContainer>
  );
};

export default ProductCard;
