import Backdrop from '../Backdrop';
import Block from '../Block';
import Button from '../Button';
import { ModalProps } from './Modal.models';
import { ModalContent, ModalWrapper } from './Modal.styles';

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  hasBackdrop = true,
  borderLess,
  hasShadow,
  hasButton = false,
  children,
}) => (
  <>
    <Backdrop isVisible={hasBackdrop && isOpen} hasButton onClose={onClose} />
    {isOpen && (
      <ModalWrapper>
        <ModalContent borderLess={borderLess} hasShadow={hasShadow}>
          {hasButton && (
            <Block display="flex" justify="flex-end">
              <Button
                customStyles={{ minWidth: '0', padding: '0' }}
                variant="icon"
                icon="closeX"
                onClick={onClose}
                iconColor="earth"
                iconSize="m"
              />
            </Block>
          )}
          {children}
        </ModalContent>
      </ModalWrapper>
    )}
  </>
);

export default Modal;
