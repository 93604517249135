import { useHistory } from 'react-router';
import { useState } from 'react';
import ErrorBoundary from '../../../../components/errorBoundary/ErrorBoundary';
import { Block, Button, CustomCircularProgress, Input, Text } from '../../../../components';
import { BasketResumeContainer } from './BasketResume.styles';
import { routes } from '../../../../routes';
import { useCart } from '../../../../hooks';
import { NumberHelper } from '../../../../helpers';

interface Props {
  discount?: number;
  subTotal: number;
  total: number;
  processPurchase: () => void;
}

const BasketResume: React.FC<Props> = ({ processPurchase, discount, subTotal, total }) => {
  const history = useHistory();
  const { clearCart, validateDiscount, isLoading } = useCart();
  const [coupon, setCoupon] = useState<string>('');
  const keepShopping = () => history.push(routes.STORE_DEPARTMENTS);

  const couponHandler = (e: any) => setCoupon(e.target.value);

  const validateCoupon = async (e: any) => {
    e.preventDefault();
    validateDiscount(coupon);
    setCoupon('');
  };

  return (
    <ErrorBoundary>
      <BasketResumeContainer>
        <Block display="flex" width="100%" mb="m">
          <Text color="black" fontFamily="title" textAlign="left" as="h3" fontsize="m">
            Resumen
          </Text>
        </Block>
        <Block display="flex" mb="m">
          <form onSubmit={validateCoupon} style={{ width: '100%' }}>
            <Block width="100%" display="flex" justify="space-between">
              <Input
                onChange={couponHandler}
                type="text"
                name="cupon"
                value={coupon}
                placeholder="introduce tu cupon"
                variant="outlined"
                inputSize="s"
                focusable={false}
              />
              {isLoading ? (
                <CustomCircularProgress />
              ) : (
                <Button
                  text="Aplicar"
                  type="submit"
                  disabled={coupon === ''}
                  variant="outlined"
                  color="earth"
                  size="s"
                />
              )}
            </Block>
          </form>
        </Block>
        <Block display="flex" direction="column" mb="m">
          <Block display="flex" justify="space-between">
            <Text color="black" fontFamily="title" textAlign="center" mb="s" as="h4" fontsize="m">
              Subtotal:
            </Text>
            <Text color="black" weight="medium">
              {NumberHelper.formatAmount(subTotal)}€
            </Text>
          </Block>
          <Block display="flex" justify="space-between">
            <Text color="black" fontFamily="title" textAlign="center" mb="s" as="h4" fontsize="m">
              Descuento:
            </Text>
            <Text color="red" fontSize="m" weight="medium">
              {NumberHelper.formatAmount(discount ?? 0)}€
            </Text>
          </Block>
          <Block display="flex" justify="space-between">
            <Text color="black" fontFamily="title" textAlign="center" mb="s" as="h4" fontsize="m">
              Total:
            </Text>
            <Text color="black" fontSize="m" weight="medium">
              {NumberHelper.formatAmount(total)}€
            </Text>
          </Block>
        </Block>
        <Block display="flex" direction="column">
          <Block display="flex" mb="m">
            <Button text="Tramitar compra" onClick={processPurchase} variant="outlined" size="s" color="earth" />
          </Block>
          <Block display="flex" justify="space-between">
            <Button
              text="Continuar comprando"
              onClick={() => keepShopping()}
              variant="outlined"
              size="s"
              color="earth"
            />
            <Button text="Vaciar cesta" onClick={() => clearCart()} variant="outlined" size="s" color="earth" />
          </Block>
        </Block>
      </BasketResumeContainer>
    </ErrorBoundary>
  );
};

export default BasketResume;
