import styled from 'styled-components';
import { margin, padding } from '../../theme';
import { LinkWrapperProps } from './CustomLink.models';

export const Wrapper = styled.div<LinkWrapperProps>`
  a {
    text-decoration: none !important;
    display: flex;
    flex-direction: ${(p) => p.direction};
    justify-content: ${(p) => p.justify};
    align-items: ${(p) => p.align};
    ${(p) => p.theme.fontSizeGenerator(p.as ?? 'a', p.fontSize ?? 'm')};
    font-family: ${(p) => p.fontFamily && p.theme.fontFamily[p.fontFamily]};
    color: ${(p) => (p.color ? p.theme.color[p.color] : p.theme.color.black)};
    text-transform: ${(p) => p.isUppercase && 'uppercase'};
    font-weight: ${(p) => p.weight && p.theme.weight[p.weight]};
    ${margin};
    ${padding};
    &:hover {
      color: ${(p) => p.hoverColor && p.theme.color[p.hoverColor]} !important;
    }
    ${(p) => p.customStyles && p.customStyles}
  }
`;
