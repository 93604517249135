import type { IHttpClient } from '../../AxiosApiClient';
import { environment, WP_POST_PATH } from '../../constants';
import { Post } from './post.models';

export interface PostRepository {
    get(id: string): Promise<string>;
    getMany(): Promise<Array<Post>>;
}

interface PostContentWordpressDto {content: {rendered: string}};
interface PostWordpressDto {
  id: string; 
  title: {rendered: string}; 
  excerpt: {rendered: string}; 
  content: {rendered: string}; 
  acf: {
    post_main_image: {
      image_src: string;
      image_alt: string;
    }
  }
}

function extractHtmlContent(renderedHtml: string, tag: string): string {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = renderedHtml;
  
  const h4Element = tempDiv.querySelector(tag);
  return  h4Element?.textContent || "";
}

export class PostWordpressRepository implements PostRepository {
  private httpClient: IHttpClient;
  private readonly URL = `${environment.WORDPRES_URL}${WP_POST_PATH}`

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  async get(id: string): Promise<string> {
    const res = await fetch(`${this.URL}/${id}`);
    const data = await res.json() as PostContentWordpressDto;
    return data.content.rendered
  }

  async getMany(): Promise<Array<Post>> {
    const res = await fetch(this.URL);
    const data = await res.json() as Array<PostWordpressDto>;
    return data?.map((el: PostWordpressDto) => {
      const {id, title, excerpt, content, acf} = el
      return {
        id,
        title: title.rendered,
        subTitle: extractHtmlContent(content?.rendered, 'h4'),
        description: extractHtmlContent(excerpt?.rendered, 'p'),
        image: {
          id: 0,
          src: acf?.post_main_image?.image_src,
          alt: acf?.post_main_image?.image_alt ?? '',
          name: acf?.post_main_image?.image_alt ?? '',
        },
      }
    })
  }
}
