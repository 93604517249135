import { DeliveryOptionItem } from '../delivery/delivery.model';

export type ProceedToCheckoutRequest = Checkout;

export interface CheckoutProduct {
  id: string;
  name: string;
  price: number;
  code: string;
  quantity: number;
  image: {
    src: string;
    alt: string;
  };
  size: string;
}

export type CheckoutCartData = {
  cartId: string;
  discount: number;
  subTotal: number;
  total: number;
  products: Array<CheckoutProduct>;
};

export interface Checkout {
  cartId: string;
  discount: number;
  subTotal: number;
  total: number;
  products: Array<CheckoutProduct>;
  deliveryInfo: DeliveryInfo;
  deliveryOption: DeliveryOption;
  isGuess: boolean;
  customerId?: string;
  guessData?: GuessData;
  orderId?: string;
}

export interface DeliveryInfo {
  address: string;
  zipCode: string;
  city: string;
  country: string;
  phone: string;
}

export interface DeliveryOption {
  type: DeliveryOptionSlug;
  amount: number;
}

export enum DeliveryOptionSlug {
  REGULAR = 'REGULAR',
  EXPRESS = 'EXPRESS',
  INTERNATIONAL = 'INTERNATIONAL',
  PICK_UP_D = 'PICK_UP_D',
  PICK_UP_J = 'PICK_UP_J',
}

export interface GuessData {
  name: string;
  lastName: string;
  email: string;
  acceptNotifications: boolean;
}

export type CheckoutDeliveryOptionItem = Omit<DeliveryOptionItem, 'id' | 'text'>;
