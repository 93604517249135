import styled from 'styled-components';

export const BasketResumeContainer = styled.div`
  box-shadow: 0px 2px 12px 1px #c7c7c7;
  width: 95%;
  text-align: left;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 300px;
  max-width: 400px;
`;
