import { Block, Button } from '../../../../components';

interface Props {
  goNext: () => void;
  goBack: () => void;
  disabled?: boolean;
}

const ButtonsContainer: React.FC<Props> = ({ goBack, goNext, disabled }) => (
  <Block width="100%" display="flex" justify="space-between" pt="l">
    <Button text="Volver" onClick={goBack} variant="outlined" size="s" color="earth" />
    <Button text="Continuar" onClick={goNext} variant="outlined" size="s" color="earth" disabled={disabled} />
  </Block>
);

export default ButtonsContainer;
