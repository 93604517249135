import { FormikValues } from 'formik';
import React from 'react';
import { Block, Text, Input, FormCard } from '../../../../components';
import { setDeliveryInfoAction } from '../../../../redux/checkout/checkout.thunks';
import { useDispatch } from 'react-redux';
import { DispatchAction } from '../../../../redux/store';
import ButtonsContainer from '../ButtonsContainer/ButtonsContainer';

interface Props {
  data: FormikValues;
  handleSteps: (step: number) => void;
  currentStep: number;
}

const clientFormData: Array<{ name: string; label: string }> = [
  {
    name: 'address',
    label: 'Direccion',
  },
  {
    name: 'zipCode',
    label: 'Codigo postal',
  },
  {
    name: 'city',
    label: 'Ciudad',
  },
  {
    name: 'country',
    label: 'Pais',
  },
  {
    name: 'phone',
    label: 'Telefono',
  },
];

const DeliveryInfo: React.FC<Props> = function ({ data, handleSteps, currentStep }) {
  const dispatch = useDispatch<DispatchAction>();
  const goNext = () => {
    dispatch(
      setDeliveryInfoAction({
        address: data?.values?.address,
        zipCode: data?.values?.zipCode,
        city: data?.values?.city,
        country: data?.values?.country,
        phone: data?.values?.phone,
      }),
    );
    handleSteps(currentStep + 1);
  };
  const { address, zipCode, city, country } = data.values;
  const isButtonDisabled = !address || !zipCode || !city || !country;
  return (
    <FormCard mb="m">
      <Block display="flex" direction="column">
        <Block width="100%" display="flex" direction="column" align="center" justify="center">
          <Block display="flex" width="100%" justify="center" pt="s" pb="s">
            <Text as="h3" weight="semiBold" fontsize="s" isUppercase>
              Direccion de envío
            </Text>
          </Block>
          {clientFormData.map((el, indx) => (
            <Block display="flex" direction="column" width="100%" pt="s" pb="s" key={indx}>
              <Text as="h4" weight="medium" fontsize="s">
                {el.label}
              </Text>
              <Input
                onChange={data.handleChange}
                onBlur={data.handleBlur}
                type="text"
                name={el.name}
                value={data.values[el.name]}
                variant="outlined"
                inputSize="s"
              />
              {data.touched[el.name] && data.errors[el.name] && (
                <Block display="flex" width="100%" justify="center">
                  <Text fontsize="s" color="red">
                    {data.errors[el.name]}
                  </Text>
                </Block>
              )}
            </Block>
          ))}
        </Block>
      </Block>
      <ButtonsContainer goBack={() => handleSteps(currentStep - 1)} goNext={goNext} disabled={isButtonDisabled} />
    </FormCard>
  );
};

export default DeliveryInfo;
