import { ProcessPaymentRequestDTO } from './payment.dtos';
import { IPaymentRepository } from './payment.repository';

export class PaymentService {
  private paymentRepo: IPaymentRepository;

  constructor(paymentRepo: IPaymentRepository) {
    this.paymentRepo = paymentRepo;
  }

  async processPayment(data: ProcessPaymentRequestDTO): Promise<void> {
    return this.paymentRepo.processPayment(data);
  }
}
