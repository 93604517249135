import styled from 'styled-components';
import { flex, margin, padding } from '../../theme';
import { LisContainerWrapperProp } from './ListContainer.models';

export const Wrapper = styled.ul<LisContainerWrapperProp>`
  display: flex;
  flex-direction: ${(p) => (p.direction ? p.direction : 'column')};
  width: ${(p) => p.width ?? null};
  height: ${(p) => p.height ?? null};
  list-style-type: ${(p) => p.listStyle};
  ${({ listStyle }) => listStyle === 'none' && ` padding: 0;`}
  margin: 0;
  ${flex};
  ${margin};
  ${padding};
  background-color: ${(p) => p.bgColor && p.bgColor};
  box-sizing: ${(p) => (p.contentBox ? 'content-box' : 'border-box')};
  ${(p) => p.customStyles && p.customStyles}
`;
