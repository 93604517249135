import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import userReducer, { UserState } from './user/user.slice';
import { ISnackbarState, snackbarReducer } from './snackbar/snackbar.reducer';
import cartReducer, { CartState } from './cart/cart.slice';
import checkoutReducer, { CheckoutState } from './checkout/checkout.slice';
import productsReducer, { ProductState } from './products/products.slice';

export interface DefaultState {
  products: ProductState;
  user: UserState;
  snackbar: ISnackbarState;
  cart: CartState;
  checkout: CheckoutState;
}

const rootReducer = combineReducers<DefaultState>({
  products: productsReducer,
  user: userReducer,
  snackbar: snackbarReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV !== 'production',
});

// const useThunkDispatch = () => useDispatch<typeof store.dispatch>();
export type RootState = ReturnType<typeof store.getState>;
export type DispatchAction = typeof store.dispatch;

export default store;
