import { useState } from 'react';
import { CartItem } from '../../../../domain/cart/cart.model';
import { BasketProductContainer, ProductImg, StyledContainer } from './BasketProduct.styles';
import { Block, Quantity, Text, Tooltip } from '../../../../components';
import Button from '../../../../components/Button';
import { useCart } from '../../../../hooks';
import { CartUpdateAction } from '../../../../domain/cart/cart.dtos';
import { NumberHelper } from '../../../../helpers';

interface Props {
  item: CartItem;
  soldOut: boolean;
}

const BasketProduct: React.FC<Props> = ({ item, soldOut }) => {
  const { updateExistingItem } = useCart();
  const productQuantity = item?.quantity;
  const productStock = item.stock ?? 1;
  const [quantity, setQuantity] = useState(productQuantity);
  const MAX = productStock;
  const isMobile = window.innerWidth <= 800;
  const isVisibleTooltip = quantity >= MAX;

  const handlerChangeQuantity = (type: 'add' | 'remove') => {
    if (type === 'add' && quantity < MAX) {
      setQuantity((lastVal) => lastVal + 1);
      updateExistingItem({ ...item, quantity }, CartUpdateAction.ADD);
      return;
    }
    if (type === 'remove') {
      setQuantity((lastVal) => lastVal - 1);
      if (quantity === 1) {
        updateExistingItem({ ...item, quantity }, CartUpdateAction.REMOVE);
      } else {
        updateExistingItem({ ...item, quantity: 1 }, CartUpdateAction.REMOVE);
      }
      return;
    }
  };

  const renderTootlip = (): JSX.Element => {
    if (!soldOut) {
      if (isVisibleTooltip) {
        return (
          <Tooltip content="No disponemos de más stock para este producto" position={isMobile ? 'top' : 'right'}>
            <Quantity quantity={quantity} handlerChangeQuantity={handlerChangeQuantity} />
          </Tooltip>
        );
      }
      return <Quantity quantity={quantity} handlerChangeQuantity={handlerChangeQuantity} />;
    }
    return (
      <Text fontsize="s" color="red">
        Agotado
      </Text>
    );
  };

  return (
    <BasketProductContainer>
      <StyledContainer width="45%">
        <Block display="flex" justify="center" align="center" pt="s" pr="s" pb="s" pl="s" bgColor="xLightSilver">
          <Text as="h3" fontsize="s">
            Producto
          </Text>
        </Block>
        <Block display="flex" pt="m" pb="m" height="100%">
          <Block width="40%" display="flex" justify="center" align="center">
            {item?.image && <ProductImg src={item.image.src} alt={item.image.alt} />}
          </Block>
          <Block width="60%" display="flex" justify="center" direction="column" pr="s" pl="s">
            <Text as="h4" fontsize="m" textAlign="center">
              {item?.name}
            </Text>
          </Block>
        </Block>
      </StyledContainer>
      <StyledContainer width={!item?.size ? "22.5%" : "15%"}>
        <Block display="flex" justify="center" align="center" pt="s" pr="s" pb="s" pl="s" bgColor="xLightSilver">
          <Text as="h3" fontsize="s">
            Cantidad
          </Text>
        </Block>
        <Block display="flex" pt="m" pb="m" height="100%" direction="column" justify="center" align="center">
          <Block width="100%" display="flex" justify="center" align="center">
            {renderTootlip()}
          </Block>
        </Block>
      </StyledContainer>
      <StyledContainer width={!item?.size ? "22.5%" : "15%"}>
        <Block display="flex" justify="center" align="center" pt="s" pr="s" pb="s" pl="s" bgColor="xLightSilver">
          <Text as="h3" fontsize="s">
            Precio
          </Text>
        </Block>
        <Block display="flex" pt="m" pb="m" height="100%" direction="column" justify="center" align="center">
          <Block width="100%" display="flex" justify="center" pr="s" pl="s">
            <Text as="h4" fontsize="s" weight="semiBold" isUppercase>
              {NumberHelper.formatAmount(item?.price)}€
            </Text>
          </Block>
        </Block>
      </StyledContainer>
      {
          item?.size && (
          <StyledContainer width="15%">
            <Block display="flex" justify="center" align="center" pt="s" pr="s" pb="s" pl="s" bgColor="xLightSilver">
              <Text as="h3" fontsize="s">
                Talla
              </Text>
            </Block>
            <Block display="flex" pt="m" pb="m" height="100%" direction="column" justify="center" align="center">
              <Block width="100%" display="flex" justify="center" pr="s" pl="s">
                <Text as="h4" fontsize="s" weight="semiBold">
                  {item?.size}
                </Text>
              </Block>
            </Block>
          </StyledContainer>
          )
        }
      <StyledContainer width="10%">
        <Block display="flex" justify="center" align="center" pt="s" pr="s" pb="s" pl="s" bgColor="xLightSilver">
          <Text as="h3" fontsize="s">
            <br />
          </Text>
        </Block>
        <Block display="flex" pt="m" pb="m" height="100%" direction="column" justify="center" align="center">
          <Block width="100%" display="flex" justify="center" pr="s" pl="s">
            <Button
              size="s"
              variant="icon"
              icon="delete"
              iconSize="xs"
              onClick={() => updateExistingItem({ ...item, quantity }, CartUpdateAction.REMOVE)}
            />
          </Block>
        </Block>
      </StyledContainer>
    </BasketProductContainer>
  );
};

export default BasketProduct;
