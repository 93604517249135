import styled from 'styled-components';

export const BasketProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  height: auto;
  width: 50%;
  box-shadow: 0px 2px 12px 1px #c7c7c7;
  text-align: center;
  min-width: 300px;
  max-width: 350px;

  ${(p) => p.theme.media.tablet`
        flex-direction: row;
        max-height: 220px;
        width: 85%;
        max-width: 700px;
    `}
  ${(p) => p.theme.media.desktop`
        flex-direction: row;
        max-height: 200px;
        width: 85%;
        max-width: 700px;
    `}
`;

export const StyledContainer = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  ${(p) => p.theme.media.tablet`
       width: ${p.width};
    `}
  ${(p) => p.theme.media.desktop`
       width: ${p.width};
    `}
`;

export const ProductContainer = styled(StyledContainer)`
  ${(p) => p.theme.media.tablet`
       width: 40%;
    `}

  ${(p) => p.theme.media.desktop`
       width: 45%;
    `}
`;

export const ProductImg = styled.img`
  object-fit: contain;
  width: 80px;
  height: 80px;
  ${(p) => p.theme.media.tablet`
       width: 100px;
       height: 100px;
    `}

  ${(p) => p.theme.media.desktop`
       width: 100px;
       height: 100px;
    `}
`;
