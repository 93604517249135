import { AxiosRequestBuilder } from '../../AxiosRequestBuilder';
import { UserLoginRequestDTO, UserLoginResponseDTO } from './Auth.dtos';

export interface IAuthRepository {
  logIn(input: UserLoginRequestDTO): Promise<UserLoginResponseDTO>;
  logOut(): Promise<void>;
}

export class AuthRepository implements IAuthRepository {
  private BASE_PATH: string = '/users';

  constructor(private requestBuilder: AxiosRequestBuilder) {
    this.requestBuilder = requestBuilder;
  }

  async logIn(input: UserLoginRequestDTO): Promise<UserLoginResponseDTO> {
    return (
      await this.requestBuilder.url(`${this.BASE_PATH}/login`).method('post').data(input).build<UserLoginResponseDTO>()
    ).data;
  }

  async logOut(): Promise<void> {
    // return this.httpClient.post(`/logout`);
  }
}
