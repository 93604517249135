import styled from 'styled-components';

export const StoreCardContainer = styled.div<{ url: string }>`
  background-image: url(${(p) => p.url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 50%;
  min-height: 250px;
  a {
    cursor: pointer;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
  }

  ${(p) => p.theme.media.tablet`
     width: 25%;
     min-height: 300px;
    `}

  ${(p) => p.theme.media.desktop`
     width: 25%;
     min-height: 380px;
    `}
`;

export const CustomText = styled.p`
  width: 100%;
  color: ${(p) => p.theme.color.white};
  ${(p) => p.theme.fontSizeGenerator('h4', 'l')};
  font-weight: ${(p) => p.theme.weight.bold};
  text-transform: uppercase;
  ${(p) => p.theme.media.mobileXl`
        ${p.theme.fontSizeGenerator('h4', 'l')};
    `}
  ${(p) => p.theme.media.tablet`
        ${p.theme.fontSizeGenerator('h4', 'l')};
    `}
    ${(p) => p.theme.media.desktop`
        ${p.theme.fontSizeGenerator('h4', 'l')};
    `}
`;
