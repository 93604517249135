import styled from 'styled-components';
import { flex } from '../../theme';
import { BlockContainerProps } from './Block.models';
import { margin, padding } from '../../theme';

export const BlockContainer = styled.div<BlockContainerProps>`
  width: ${(p) => p.width ?? null};
  height: ${(p) => p.height ?? null};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  box-sizing: ${(p) => p.boxSizing && p.boxSizing};
  background-color: ${(p) => p.bgColor && p.theme.color[p.bgColor]};
  position: ${(p) => p.position && p.position};
  ${flex};
  ${(p) => (!p.marginLess ? margin : 'margin: 0')};
  ${(p) => (!p.paddingLess ? padding : 'padding: 0')};
  ${(p) => p.overflow && p.overflow}
  ${(p) => p.customStyles && p.customStyles}
`;
