import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { environment } from './constants';

export type Response<T> = AxiosResponse<T>;
export type RequestConfig = AxiosRequestConfig;
type HttpMethod = 'get' | 'post' | 'put' | 'delete';
export class AxiosRequestBuilder {
  private config: RequestConfig;
  private readonly BASE_URL: string = environment.API;
  private defaultHeaders: Readonly<Record<string, string>> = {
    'Access-Control-Allow-Origin': environment.ORIGIN,
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
    'Content-Type': 'application/json',
  };

  constructor() {
    this.config = {
      method: '',
      url: '',
      data: null,
      params: null,
      headers: this.defaultHeaders,
    };
  }

  method(method: HttpMethod) {
    this.config.method = method;
    return this;
  }

  url(path: string) {
    this.config.url = `${this.BASE_URL}${path}`;
    return this;
  }

  data<T>(data: T) {
    this.config.data = data;
    return this;
  }

  params(params: { [key: string]: string }) {
    this.config.params = params;
    return this;
  }

  headers(headers: { [key: string]: string }) {
    this.config.headers = headers;
    return this;
  }

  build<T>(): Promise<Response<T>> {
    return axios(this.config);
  }
}

export const requestBuilder = new AxiosRequestBuilder();
