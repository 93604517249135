import { AxiosHttpClient } from './AxiosApiClient';
import { AxiosRequestBuilder } from './AxiosRequestBuilder';
import { AuthRepository } from './domain/auth/AuthRepository';
import { BlogRepository, IBlogRepository } from './domain/blog/blog.repository';
import { BlogService } from './domain/blog/blog.service';
import { CartRepository } from './domain/cart/cart.repository';
import { CartService } from './domain/cart/cart.service';
import { CouponRepository } from './domain/coupon/coupon.repository';
import { CouponService } from './domain/coupon/coupon.service';
import { DeliveryRepository } from './domain/delivery/delivery.repository';
import { DeliveryService } from './domain/delivery/delivery.service';
import { NewsLetterRepository } from './domain/newsletter/newsletter.repository';
import { NewsLetterService } from './domain/newsletter/newsletter.service';
import { OrderRepository } from './domain/order/order.repository';
import { OrderService } from './domain/order/order.service';
import { PaymentRepository } from './domain/payment/payment.repository';
import { PaymentService } from './domain/payment/payment.service';
import { PostWordpressRepository } from './domain/post/post-wordpress.repository';
import { PostService } from './domain/post/post.service';
import { ProductRepository } from './domain/product/product.repository';
import { ProductService } from './domain/product/product.service';
import { SliderRepository } from './domain/slider/slider.repository';
import { SliderService } from './domain/slider/slider.service';
import { IUserRepository, UserRepository } from './domain/user/user.repository';
import { UserService } from './domain/user/user.service';

export class Container {
  bindMap = new Map();

  bind(identifier: string, intancedClass: any) {
    this.bindMap.set(identifier, intancedClass);
  }

  get<T>(identifier: string): T {
    return this.bindMap.get(identifier);
  }
}

const container = new Container();
const requestBuilder = new AxiosRequestBuilder();

const axiosHttpClient = new AxiosHttpClient();
const productRepository = new ProductRepository(axiosHttpClient);
const sliderRepository = new SliderRepository(axiosHttpClient);
const productService = new ProductService(productRepository);
const sliderService = new SliderService(sliderRepository);
const blogRepository: IBlogRepository = new BlogRepository(axiosHttpClient);
const blogService = new BlogService(blogRepository);
const userRepository: IUserRepository = new UserRepository(axiosHttpClient);
const userService = new UserService(userRepository);
const cartRepository = new CartRepository(axiosHttpClient);
const cartService = new CartService(cartRepository);
const cuponRepository = new CouponRepository(axiosHttpClient);
const cuponService = new CouponService(cuponRepository);
const paymentRepository = new PaymentRepository(axiosHttpClient);
const paymentService = new PaymentService(paymentRepository);
const orderRepository = new OrderRepository(axiosHttpClient);
const orderService = new OrderService(orderRepository);
const deliveryRepository = new DeliveryRepository(axiosHttpClient);
const deliveryService = new DeliveryService(deliveryRepository);
const newsLetterRepository = new NewsLetterRepository(axiosHttpClient);
const newsLetterService = new NewsLetterService(newsLetterRepository);
const postRepository = new PostWordpressRepository(axiosHttpClient);
const postService = new PostService(postRepository);

const authRepository = new AuthRepository(requestBuilder);

container.bind('AuthRepository', authRepository);

container.bind('AxiosHttpClient', axiosHttpClient);
container.bind('ProductRepository', productRepository);
container.bind('SliderRepository', sliderRepository);
container.bind('ProductService', productService);
container.bind('SliderService', sliderService);
container.bind('BlogRepository', blogRepository);
container.bind('BlogService', blogService);
container.bind('UserService', userService);
container.bind('CartService', cartService);
container.bind('CouponService', cuponService);
container.bind('PaymentService', paymentService);
container.bind('OrderService', orderService);
container.bind('DeliveryService', deliveryService);
container.bind('NewsLetterService', newsLetterService);
container.bind('PostService', postService);

export default container;
