import { Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../../components/errorBoundary/ErrorBoundary';
import { routes } from '../../routes';
import CartPage from './CartPage';

const CartRoutes = () => (
  <ErrorBoundary>
    <Switch>
      <Route path={routes.CART}>
        <CartPage />
      </Route>
    </Switch>
  </ErrorBoundary>
);

export default CartRoutes;
