import { FormikValues } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultState, DispatchAction } from '../../../../redux/store';
import { createOrderAction } from '../../../../redux/checkout/checkout.thunks';
import { selectCheckoutState } from '../../../../redux/checkout/checkout.selectors';
import { CheckoutState } from '../../../../redux/checkout/checkout.slice';
import { useContext, useEffect, useState } from 'react';
import { CheckoutDeliveryOptionItem } from '../../../../domain/checkout/checkout.model';
import { ServicesContext } from '../../../../contexts/ServicesContext';
import SnackbarAction from '../../../../redux/snackbar/snackbar.actions';
import { Spinner } from '../../../../components';
import { Block, Text, Radio, CustomLink, FormCard } from '../../../../components';
import ButtonsContainer from '../ButtonsContainer/ButtonsContainer';
import { NumberHelper } from '../../../../helpers';

interface Props {
  data: FormikValues;
  handleSteps: (step: number) => void;
  currentStep: number;
}

const DeliveryOptionContainer: React.FC<Props> = ({ data, handleSteps, currentStep }) => {
  const { deliveryService } = useContext(ServicesContext)!;
  const [deliveryOptions, setDeliveryOptions] = useState<Array<CheckoutDeliveryOptionItem>>([]);
  const { subTotal } = useSelector<DefaultState, CheckoutState>(selectCheckoutState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isDeliveryFree = subTotal >= 40;
  const dispatch = useDispatch<DispatchAction>();

  const goNext = () => {
    try {
      const selectedOption = deliveryOptions?.find((el) => el.slug === data?.values?.delivery);
      const deliveryAmount = isDeliveryFree ? 0 : selectedOption?.amount ?? 0;
      dispatch(
        createOrderAction({
          type: data?.values?.delivery,
          amount: deliveryAmount,
        }),
      );
      handleSteps(currentStep + 1);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const availableDeliveryOptions = await deliveryService.getOptions();
        if (availableDeliveryOptions?.length > 0) {
          setDeliveryOptions(availableDeliveryOptions);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        dispatch(SnackbarAction.error('Ha ocurrido un error al cargar las opciones de envio'));
      }
    })();
    return () => {};
  }, []);

  if (isLoading) {
    return <Spinner top="25%" />;
  }

  return (
    <FormCard mb="m">
      <Block display="flex" direction="column">
        <Block display="flex" width="100%" justify="center" pt="s" pb="s">
          <Text as="h3" weight="semiBold" fontsize="s" isUppercase>
            Opciones de envío
          </Text>
        </Block>
        {deliveryOptions.slice(0, 3).map((el, i) => (
          <Block display="flex" width="100%" pt="s" pb="s" align="center" key={i}>
            <Block display="flex" width="90%" align="center">
              <Radio
                onChange={data?.handleChange}
                name="delivery"
                value={el.slug}
                checked={data?.values.delivery === el.slug}
                inputSize="s"
              />
              <Text as="h5" weight="medium" fontsize="s" ml="s">
                {el.label}
              </Text>
            </Block>
            <Block display="flex" width="10%" justify="center">
              <Text fontsize="m" weight="medium">{`${isDeliveryFree ? '0.00' : NumberHelper.formatAmount(el.amount, 2)}€`}</Text>
            </Block>
          </Block>
        ))}
        <Block display="flex" width="100%" justify="center" pt="s" pb="s">
          <Text as="h4" weight="semiBold" fontsize="s" isUppercase>
            Puntos de recogida
          </Text>
        </Block>
        {deliveryOptions.slice(3).map((el, i) => (
          <Block display="flex" width="100%" pt="s" pb="s" align="center" key={i}>
            <Block display="flex" width="90%" align="center">
              <Radio
                onChange={data?.handleChange}
                name="delivery"
                value={el.slug}
                checked={data?.values.delivery === el.slug}
                inputSize="s"
              />
              <Text as="h5" weight="medium" fontsize="s" ml="s">
                {el.label}
              </Text>
            </Block>
            <Block display="flex" width="10%" justify="center">
              <Text fontsize="m" weight="medium">{`${isDeliveryFree ? '0.00' : NumberHelper.formatAmount(el.amount, 2)}€`}</Text>
            </Block>
          </Block>
        ))}
        <Block display="flex" justify="center">
          <CustomLink fontSize="m" hoverColor="darkSilver" to="/tramitacion-envios" align="center">
            Más informacion.
          </CustomLink>
        </Block>
      </Block>
      <ButtonsContainer goBack={() => handleSteps(currentStep - 1)} goNext={goNext} />
    </FormCard>
  );
};

export default DeliveryOptionContainer;
