import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// the useTranslations has the object i18n that contains the method i18n.changeLanguage('en') to change the languague

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: 'es',
    resources: {
      en: {
        home: require('./locales/en/home.json'),
        footer: require('./locales/en/footer.json'),
        prueba: require('./locales/en/prueba.json'),
      },
      es: {
        home: require('./locales/es/home.json'),
        footer: require('./locales/en/footer.json'),
        prueba: require('./locales/es/prueba.json'),
      },
    },
    ns: ['home'],
    defaultNS: 'home',
  });

i18n.languages = ['en', 'es'];

export default i18n;
