import styled from 'styled-components';
import { CheckBoxProps } from './Checkbox';
import { checkboxOrRadioSizes } from '../../theme';

export const Wrapper = styled.div`
  line-height: 1.4375em;
  box-sizing: border-box;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;

export const InputContainer = styled.input.attrs(({ type }) => ({
  type: type || 'password',
}))<CheckBoxProps>`
  border: 0;
  box-sizing: content-box;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  background: none;
  padding: 0.563;
  width: ${(p) => (p.inputSize ? `${checkboxOrRadioSizes[p.inputSize]}px` : '100%')};
  height: ${(p) => (p.inputSize ? `${checkboxOrRadioSizes[p.inputSize]}px` : 'auto')};
  &:focus {
    border-color: rgb(219, 198, 102);
    box-shadow: 0 0 1px rgb(219, 198, 102) inset, 0 0 8px rgba(219, 198, 102, 0.6);
    outline: 0 none;
  }
`;
// TODO: when error is true rgb(255, 0, 0) and rgba(255, 0, 0, 1)
