import { useState } from 'react';
import Block from '../Block';
import Button from '../Button';
import Input from '../Input';
import { MenuContainer, MenuItem, SizePickeryWrapper } from './SizePicker.styles';

interface Props {
  selectedSize: string;
  sizes: Array<string>;
  handleSelectSize: (size: string) => void;
}

const SizePicker: React.FC<Props> = ({ selectedSize, sizes, handleSelectSize }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const showMenuHandler = () => setIsMenuOpen(!isMenuOpen);
  const sizesToShow = sizes.filter((size) => size !== selectedSize);
  return (
    <SizePickeryWrapper isOpen={isMenuOpen}>
      <Block position="relative" display='flex' align='center' justify='space-between'>
        <Input
          inputSize="s"
          width="100%"
          type="text"
          onChange={() => null}
          name="size"
          value={selectedSize}
          focusable={false}
          readonly
        />
        <Button size="s" variant="icon" icon="downArrow" iconSize="xs" onClick={(e) => showMenuHandler()} />

        {isMenuOpen && (
          <MenuContainer isOpen={isMenuOpen}>
            {sizesToShow.map((size) => (
              <MenuItem
                key={size}
                onClick={() => {
                  handleSelectSize(size);
                  showMenuHandler();
                }}>
                <Input
                  inputSize="s"
                  width="100%"
                  type="text"
                  onChange={() => null}
                  name="size"
                  value={size}
                  focusable={false}
                  readonly
                />
              </MenuItem>
            ))}
          </MenuContainer>
        )}
      </Block>
    </SizePickeryWrapper>
  );
};

export default SizePicker;
