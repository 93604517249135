import React from 'react';
import AnilloMovil from '../../assets/images/sizes-guide/anklets-mobile.webp';
import AnilloPC from '../../assets/images/sizes-guide/anklets.webp';
import ColgantesMovil from '../../assets/images/sizes-guide/pendants-mobile.webp';
import ColgantesPC from '../../assets/images/sizes-guide/pendants.webp';
import MunecahMovil from '../../assets/images/sizes-guide/man-bracelets-mobile.webp';
import MunecahPC from '../../assets/images/sizes-guide/man-bracelets.webp';
import MunecamMovil from '../../assets/images/sizes-guide/woman-bracelets-mobile.webp';
import MunecamPC from '../../assets/images/sizes-guide/woman-bracelets.webp';
import MunecaMovil from '../../assets/images/sizes-guide/kids-bracelets-mobile.webp';
import MunecaPC from '../../assets/images/sizes-guide/kids-bracelets.webp';
import TobillerasMovil from '../../assets/images/sizes-guide/anklets-mobile.webp';
import TobillerasPC from '../../assets/images/sizes-guide/anklets.webp';

const GuiaTallas = function () {
  const pcImages = [ColgantesPC, AnilloPC, MunecahPC, MunecamPC, MunecaPC, TobillerasPC];
  const movilImages = [ColgantesMovil, AnilloMovil, MunecahMovil, MunecamMovil, MunecaMovil, TobillerasMovil];

  let imagesToShow;
  if (window.innerWidth < 576) {
    imagesToShow = movilImages;
  } else {
    imagesToShow = pcImages;
  }

  return (
    <div>
      {imagesToShow.map((image, index) => (
        <img key={index} alt={`Tallas${index}`} src={image} style={{ width: '50%', margin: '3%' }} />
      ))}
    </div>
  );
};

export default GuiaTallas;
