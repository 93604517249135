import { PostRepository } from './post-wordpress.repository';
import { Post } from './post.models';

export class PostService {
  private repository: PostRepository;

  constructor(repository: PostRepository) {
    this.repository = repository;
  }

  async getPosts(): Promise<Array<Post>> {
    return this.repository.getMany();
  }

  async getPostDetail(id: string): Promise<string | null> {
    return this.repository.get(id);
  }
}
