import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from '../../routes';

const ShippingProcessing = lazy(() => import('./shippingProcessing'));
const CookiesPolicy = lazy(() => import('./cookiesPolicy'));
const FAQ = lazy(() => import('./faq'));
const PrivacyPolicy = lazy(() => import('./privacyPolicy'));
const ReturnPolicy = lazy(() => import('./returnPolicy'));
const KnowUs = lazy(() => import('./know-us'));

const InfoRoutes = function () {
  return (
    <Switch>
      <Route component={ShippingProcessing} path={routes.SHIPPING_PROCESSING} />
      <Route component={CookiesPolicy} path={routes.COOKIES_POLICY} />
      <Route component={ReturnPolicy} path={routes.RETURN_POLICY} />
      <Route component={PrivacyPolicy} path={routes.PRIVACY_POLICY} />
      <Route component={FAQ} path={routes.FAQ} />
      <Route component={KnowUs} path={routes.KNOW_US} />
    </Switch>
  );
};

export default InfoRoutes;
