import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ToolbarLogoContainer = styled(Link)<{ $isShrunk: boolean }>`
  justify-content: center;
  overflow: hidden;
  width: 50%;
  max-width: 180px;
  ${(p) =>
    p.$isShrunk &&
    `
      max-width: 150px;
    `}
`;

export const ToolbarLogo = styled.img<{ $isShrunk: boolean }>`
  height: auto;
  transition: 1s;
  width: 100%;
  height: 100%;
`;
