import { useHistory, useParams } from 'react-router-dom';
import woman from '../../../assets/images/store/woman.webp';
import man from '../../../assets/images/store/man.webp';
import take_care from '../../../assets/images/store/take-care.webp';
import sales from '../../../assets/images/store/sales.webp';
import spirituality from '../../../assets/images/store/spirituality.webp';
import bracelets from '../../../assets/images/store/bracelets.webp';
import pendants from '../../../assets/images/store/pendants.webp';
import earrings from '../../../assets/images/store/earrings.webp';
import rings from '../../../assets/images/store/rings.webp';
import leaves from '../../../assets/images/store/leaves.webp';
import ErrorBoundary from '../../../components/errorBoundary/ErrorBoundary';
import { Container, CustomText, StoreCard, StoreCardContainer } from './StoreDepartments.styles';
import { Block, CustomLink, Text } from '../../../components';
import { routes } from '../../../routes';
import { useEffect, useState } from 'react';
import { StringHelper } from '../../../helpers';

interface StoreDepartmentItem {
  name: string;
  image: string;
}

interface StoreMainDepartmentItem extends StoreDepartmentItem {
  subDepartments?: Array<StoreDepartmentItem>;
}
const mainDepartments: Array<StoreMainDepartmentItem> = [
  {
    name: 'mujer',
    image: woman,
    subDepartments: [
      {
        name: 'pulseras',
        image: bracelets,
      },
      {
        name: 'anillos',
        image: rings,
      },
      {
        name: 'colgantes',
        image: pendants,
      },
      {
        name: 'pendientes',
        image: earrings,
      },
    ],
  },
  {
    name: 'hombre',
    image: man,
  },
  {
    name: 'cuidado de tu ser',
    image: take_care,
  },
  {
    name: 'REBAJAS',
    image: sales,
  },
  {
    name: 'espiritualidad',
    image: spirituality,
  },
];
const StoreDepartmentsPage = () => {
  const history = useHistory();
  const { department } = useParams<{ department: string }>();
  const [cardsToRender, setCardsToRender] = useState<Array<StoreMainDepartmentItem>>(mainDepartments);

  useEffect(() => {
    if (department) {
      const subDepartmentsToRender = mainDepartments.find((item) => item.name === department)?.subDepartments ?? [];
      if (subDepartmentsToRender?.length > 0) {
        setCardsToRender(subDepartmentsToRender);
      } else {
        history.push(routes.STORE_DEPARTMENTS);
      }
    } else {
      setCardsToRender(mainDepartments);
    }
    window.scrollTo(0, 0);
    return () => {};
  }, [department, history.location.pathname]);

  const onClickHandler = (item: StoreMainDepartmentItem) => {
    if (item.subDepartments && item.subDepartments.length > 0) {
      history.push(`${routes.STORE_DEPARTMENTS}/${StringHelper.formateEmptySpacesToUrl(item.name)}`);
    } else {
      history.push(`${routes.STORE_PRODUCTS}/${StringHelper.formateEmptySpacesToUrl(item.name)}`);
    }
  };
  const departmentsSection = cardsToRender.map((item, index) => {
    return (
      <StoreCard key={index} url={item.image} onClick={() => onClickHandler(item)}>
        <Block>
          <CustomText>{item.name}</CustomText>
        </Block>
      </StoreCard>
    );
  });

  return (
    <ErrorBoundary>
      <Container url={leaves}>
        <StoreCardContainer>{departmentsSection}</StoreCardContainer>
        <Block display="flex" direction="column" align="center" justify="center" pt="l" pb="l">
          <Text as="h3" fontsize="l" fontFamily="title">
            ¿Conóces tú talla?
          </Text>
          <CustomLink to={routes.SIZE_GUIDES} fontSize="m" color="black" hoverColor="lightSilver">
            Guía de tallas
          </CustomLink>
        </Block>
      </Container>
    </ErrorBoundary>
  );
};

export default StoreDepartmentsPage;
