import {
  ConfirmRecoveryPasswordRequestDTO,
  CreateUserRequestDTO,
  RecoveryPasswordRequestDTO,
  RemoveRequestDTO,
  UserLoginRequestDTO,
  UserUpdateRequestDTO,
} from './user.dtos';
import { User } from './user.models';
import { IUserRepository } from './user.repository';

export class UserService {
  private userRepo: IUserRepository;

  constructor(userRepo: IUserRepository) {
    this.userRepo = userRepo;
  }

  async create(data: CreateUserRequestDTO): Promise<void> {
    return this.userRepo.create(data);
  }

  async logIn(input: UserLoginRequestDTO): Promise<User> {
    return this.userRepo.logIn(input);
  }

  async logOut(): Promise<void> {
    return this.userRepo.logOut();
  }

  async recoveryPassword(input: RecoveryPasswordRequestDTO): Promise<void> {
    return this.userRepo.recoveryPassword(input);
  }

  async confirmRecoveryPassword(input: ConfirmRecoveryPasswordRequestDTO): Promise<void> {
    return this.userRepo.confirmRecoveryPassword(input);
  }

  async remove(input: RemoveRequestDTO): Promise<void> {
    return this.userRepo.remove(input);
  }

  async refreshFromJwt(): Promise<User> {
    return this.userRepo.refreshFromJwt();
  }

  async updateUser(params: UserUpdateRequestDTO): Promise<void> {
    return this.userRepo.updateUser(params);
  }
}
