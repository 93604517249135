import styled from 'styled-components';

export const LogoImg = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
  transform: rotate(25deg);
  position: absolute;
  left: -12%;
  top: -55%;

  ${(p) => p.theme.media.mobileXl`
        width: 70px;
        height: 70px;
    `}
  ${(p) => p.theme.media.tablet`
        width: 70px;
        height: 70px;
    `}
    ${(p) => p.theme.media.desktop`
        width: 70px;
        height: 70px;
    `}
`;