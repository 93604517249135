import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Text from '../../components/Text';
import Block from '../../components/Block';
import BasketProduct from './components/BasketProduct/BasketProduct';
import { StyledBlock } from './CartPage.styles';
import BasketResume from './components/BasketResume/BasketResume';
import { PageContainer, SoldOutProductModal } from '../../components';
import { routes } from '../../routes';
import { useCart } from '../../hooks';
import { getSoldOutProducts } from '../../redux/products/products.thunks';

const CartPage = function () {
  const history = useHistory();
  const { cart } = useCart();
  const cartItems = cart?.items ?? [];
  const [solOutProductsIds, setSoldProductsIds] = useState<Array<string>>([]);
  const [soldOutModal, setSoldOutModal] = useState<{
    open: boolean;
    title: string;
    text: string;
    data: Array<string>;
  }>({
    open: false,
    title: 'Productos agotados',
    text: 'Por favor eliminalos para poder continuar',
    data: [],
  });
  const processPurchase = () => {
    if (solOutProductsIds?.length > 0) {
      cartItems?.filter((item) => {
        if (solOutProductsIds?.includes(item.id)) {
          soldOutModal.data.push(item.name);
        }
      });
      setSoldOutModal({
        ...soldOutModal,
        open: true,
      });
    } else {
      history.push(routes.CHECKOUT);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      if (cartItems && cartItems.length > 0) {
        const productsToCheck = cartItems?.map((product, i) => ({
          id: product.id,
          quantity: product.quantity,
        }));
        const soldOutProducts = await getSoldOutProducts(productsToCheck);
        if (soldOutProducts?.length > 0) {
          setSoldProductsIds(soldOutProducts.map((item) => item.id));
        }
      }
    })();
    return () => {};
  }, []);
  const handleCloseSoldOutModal = () => setSoldOutModal({ ...soldOutModal, open: false });

  return (
    <PageContainer pageTitle="Cesta">
      <SoldOutProductModal
        onClose={handleCloseSoldOutModal}
        isOpen={soldOutModal.open}
        title={soldOutModal.title}
        text={soldOutModal.text}
        soldOutProductNames={soldOutModal?.data}
      />
      <Block>
        <StyledBlock>
          {cartItems?.length > 0 ? (
            cartItems?.map((item, index) => (
              <BasketProduct soldOut={solOutProductsIds.includes(item.id)} key={index} item={item} />
            ))
          ) : (
            <Block display="flex" direction="column" justify="center" width="100%">
              <Text as="h2" color="lightSilver" textAlign="center" fontsize="s">
                No tienes Productos en tu cesta
              </Text>
              <Link style={{ color: '#a78742', fontSize: '1rem' }} to={routes.STORE_DEPARTMENTS}>
                Seguir Comprando
              </Link>
            </Block>
          )}
        </StyledBlock>
      </Block>
      <Block display="flex" align="center" justify="center" mt="m" mb="m">
        {cartItems?.length > 0 && (
          <BasketResume
            discount={cart?.discount}
            subTotal={cart?.subTotal ?? 0}
            total={cart?.total ?? 0}
            processPurchase={processPurchase}
          />
        )}
      </Block>
    </PageContainer>
  );
};

export default CartPage;
