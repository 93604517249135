import { Link } from 'react-router-dom';
import woman from '../../../../assets/images/store/woman.webp';
import man from '../../../../assets/images/store/man.webp';
import take_care from '../../../../assets/images/store/take-care.webp';
import spirituality from '../../../../assets/images/store/spirituality.webp';
import ErrorBoundary from '../../../../components/errorBoundary/ErrorBoundary';
import Block from '../../../../components/Block';
import { StoreCardContainer, CustomText } from './StoreCards.styles';
import { routes } from '../../../../routes';
import { useTranslation } from 'react-i18next';

const StoreCards = () => {
  const { t } = useTranslation('home');
  const menuArray = [
    {
      name: t('cards.woman'),
      image: woman,
      path: `${routes.STORE_PRODUCTS}/mujer`,
    },
    {
      name: t('cards.man'),
      image: man,
      path: `${routes.STORE_PRODUCTS}/hombre`,
    },
    {
      name: t('cards.take_care'),
      image: take_care,
      path: `${routes.STORE_PRODUCTS}/cuidado-de-tu-ser`,
    },
    {
      name: t('cards.spirituality'),
      image: spirituality,
      path: `${routes.STORE_PRODUCTS}/espiritualidad`,
    },
  ];
  const renderCards = menuArray.map((item, index) => (
    <StoreCardContainer key={index} url={item.image}>
      <Link to={item.path}>
        <Block display="flex" height="100%" align="center">
          <CustomText>{item.name}</CustomText>
        </Block>
      </Link>
    </StoreCardContainer>
  ));

  return (
    <ErrorBoundary>
      <Block display="flex" flexWrap="wrap">
        {renderCards}
      </Block>
    </ErrorBoundary>
  );
};

export default StoreCards;
