import React from 'react';
import { InputBaseProps, Size } from '../../theme';
import { InputContainer, Wrapper } from './Radio.styles';

export interface RadioProps extends InputBaseProps {
  checked?: boolean;
  inputSize?: Size;
}

const Radio: React.FC<RadioProps> = (props) => (
  <Wrapper>
    <InputContainer {...props} type="radio" />
  </Wrapper>
);

export default React.memo(Radio);
