import { Switch, Route } from 'react-router-dom';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';
import React, { lazy } from 'react';
import { Product } from './domain/product/product.model';
import { routes } from './routes';
import SizeGuide from './pages/sizeGuide';
import StoreRoutes from './pages/store/StoreRoutes';
import InfoRoutes from './pages/infoPages';
import UserRoutes from './pages/user/UserRoutes';
import CheckoutRoutes from './pages/checkout/CheckoutRoutes';
import HomePage from './pages/home'
import InvalidTokenPage from './pages/invalidToken';
import BlogRoutes from './pages/blog/BlogRoutes';
import CartRoutes from './pages/cart/CartRoutes';

interface Props {
  mosaicData: Array<Product>;
}

const AppRoutes: React.FC<Props> = ({ mosaicData }) => {
  // const SizeGuide = lazy(() => import('./pages/sizeGuide'));
  // const StoreRoutes = lazy(() => import('./pages/store'));
  // const InfoRoutes = lazy(() => import('./pages/infoPages'));
  // const UserRoutes = lazy(() => import('./pages/user/UserRoutes'));
  // const CheckoutRoutes = lazy(() => import('./pages/checkout/CheckoutRoutes'));
  // const HomePage = lazy(() => import('./pages/home'));
  // const InvalidTokenPage = lazy(() => import('./pages/invalidToken'));
  // const BlogRoutes = lazy(() => import('./pages/blog/BlogRoutes'));
  // const CartRoutes = lazy(() => import('./pages/cart/CartRoutes'));

  return (
    <ErrorBoundary>
      <Switch>
        <HomePage dataToMosaic={mosaicData?.length > 0 ? mosaicData : []} exact path={routes.BASE} />
        <Route path={routes.SIZE_GUIDES}>
          <SizeGuide />
        </Route>
        <Route path={routes.INVALID_TOKEN}>
          <InvalidTokenPage />
        </Route>
      </Switch>
      <BlogRoutes />
      <CartRoutes />
      <CheckoutRoutes />
      <InfoRoutes />
      <StoreRoutes />
      <UserRoutes />
    </ErrorBoundary>
  );
};

export default AppRoutes;
