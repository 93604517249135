import 'core-js/stable';
import './i18n/config';
import React from 'react';
import ReactDOM from 'react-dom';

// import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as serviceWorker from './serviceWorker';
import App from './App';
import store from './redux/store';
import StyleProvider from './theme/StyleProvider';
import { ServicesProvider } from './contexts/ServicesContext';
import { environment } from './constants';
// import { MixPanelProvider } from './contexts/MixPanelContext';

if (window.Cypress) {
  window.store = store;
}
const stripePromise = loadStripe(`${environment.STRIPE_KEY}`);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <StyleProvider>
            <ServicesProvider>
              {/* <MixPanelProvider> */}
                <App />
              {/* </MixPanelProvider> */}
            </ServicesProvider>
          </StyleProvider>
        </BrowserRouter>
      </Elements>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
serviceWorker.unregister();
