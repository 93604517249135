import React from 'react';
import banner from '../../assets/images/sinag-banner.webp';
import promotionMobileImg from '../../assets/images/promotion-home-mobiile.jpeg';
import promotionWebImg from '../../assets/images/promotion-home-web.jpeg';
import Mosaic from './components/Mosaic/Mosaic';
import Video from '../../components/Video';
import main_video from '../../assets/videos/home_video.mp4';
import { Product } from '../../domain/product/product.model';
import StoreCards from './components/StoreCards/StoreCards';
import { Block, NewsLetter } from '../../components';
import { HomeBannerContainer, HomeBannerImage, HomeBlock } from './HomePage.styles';

export interface HomeProps {
  dataToMosaic: Array<Product>;
  exact?: boolean;
  path?: string;
}

const HomePage: React.FC<HomeProps> = ({ dataToMosaic }) => {
  return (
    <Block display="flex" direction="column" width="100%" position="relative">
      <HomeBlock>
        <Video src={main_video} />
      </HomeBlock>
      <StoreCards />
      <HomeBannerContainer>
        <HomeBannerImage src={banner} alt="envios a toda europa" />
      </HomeBannerContainer>
      {dataToMosaic && <Mosaic data={dataToMosaic} />}
      <NewsLetter />
    </Block>
  );
};

export default HomePage;
