import { BlockProps } from '../Block';
import { StyledContainer } from './FormCard.styles';

interface Props extends BlockProps {}

const FormCard: React.FC<Props> = (props) => (
  <StyledContainer display={props.display ?? 'flex'} direction={props.direction ?? 'column'} {...props}>
    {props.children}
  </StyledContainer>
);

export default FormCard;
