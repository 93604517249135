import React from 'react';
import { BadgeContainer } from './Badge.styles';
import { BadgeProps } from './Badge.models';
import Block from '../Block';
import Text from '../Text';

const Badge: React.FC<BadgeProps> = ({
  isVisible,
  value,
  children,
  bgColor,
  color,
  max = 9,
  size,
  alignment = {
    x: 'right',
    y: 'top',
  },
}) => {
  const isGreater = max && value > max;
  return (
    <Block position="relative">
      <Block position="relative">{children}</Block>
      {isVisible && (
        <Block position="absolute" customStyles={{ bottom: '15px', right: '-12px' }}>
          <BadgeContainer bgColor={bgColor}>
            <Text fontsize="s" color={color ?? 'white'} weight="semiBold">
              {isGreater ? `${max}+` : value}
            </Text>
          </BadgeContainer>
        </Block>
      )}
    </Block>
  );
};

export default Badge;
