import { Slider } from './slider.model';
import { ISliderRepository } from './slider.repository';

export class SliderService {
  private sliderRepo: ISliderRepository;

  constructor(sliderRepo: ISliderRepository) {
    this.sliderRepo = sliderRepo;
  }

  async getData(): Promise<Array<Slider>> {
    return this.sliderRepo.getMany();
  }
}
