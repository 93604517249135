import { Product, ProductToCheckAvailabilityRequest } from './product.model';
import { Criteria, IProductRepository } from './product.repository';
import { GetProductsResponseDTO } from './products.dtos';

export class ProductService {
  private productRepo: IProductRepository;

  constructor(productRepo: IProductRepository) {
    this.productRepo = productRepo;
  }

  async getMosaicItems(): Promise<Array<Product>> {
    const { products } = await this.productRepo.getMany({
      field: 'categories',
      value: 'mosaico',
    });
    return products;
  }

  async getById(id: string): Promise<Product> {
    return this.productRepo.get(id);
  }

  async getDetail(productName: string): Promise<Product> {
    return this.productRepo.getDetail({
      field: 'name',
      value: productName,
      limit: 1,
      operator: 'LIKE',
    });
  }

  async getByCategory(field: string): Promise<Array<Product>> {
    const { products } = await this.productRepo.getMany({
      field: 'categories',
      value: field,
    });
    return products;
  }

  async getByCriteria(criteria?: Criteria): Promise<GetProductsResponseDTO> {
    return this.productRepo.getMany(criteria);
  }

  async checkAvailability(products: ProductToCheckAvailabilityRequest): Promise<
    Array<{
      id: string;
      stock: number;
    }>
  > {
    return this.productRepo.checkAvailability(products);
  }
}
