import Modal from '../Modal/Modal';
import { ModalBaseProps } from '../Modal/Modal.models';
import { CustomText, StyledBlock, StyledStrong } from './MarketingModal.styles';
import background_img from '../../assets/images/marketing-background.webp';
import { Block, Button, Text } from '..';
import { useHistory } from 'react-router';
import { routes } from '../../routes';

interface Props extends ModalBaseProps {}

const MarketingModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const history = useHistory();
  const goToRegister = () => {
    onClose();
    history.push(routes.REGISTER);
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} borderLess hasShadow>
      <StyledBlock
        url={background_img}
        display="flex"
        direction="column"
        align="center"
        justify="center"
        pt="s"
        pr="l"
        pb="s"
        pl="l"
        customStyles={{ minWidth: '300px', minHeight: '440px' }}
      >
        <Block display="flex" direction="column" align="center">
          <Block display="flex" direction="column" height="100%" align="center" mb="l">
            <CustomText>¡Unete a sinag!</CustomText>
            <Text customStyles={{ fontSize: '1.2rem' }} color="black">
              Obten un <StyledStrong>20%</StyledStrong> de descuento
            </Text>
          </Block>
          <Block display="flex" /* pt="s" pb="s" */>
            <Button
              text="Registrarse"
              type="button"
              variant="default"
              color="xLightSilver"
              size="l"
              onClick={() => goToRegister()}
            />
          </Block>
        </Block>
      </StyledBlock>
    </Modal>
  );
};

export default MarketingModal;
