import { useHistory } from 'react-router';
import { Block, Text, FormCard, Button } from '../../../../components';
import { routes } from '../../../../routes';

const CustomerData = () => {
  const history = useHistory<any>();
  return (
    <FormCard mb="m">
      <Block display="flex" direction="column">
        <Block display="flex" direction="column" align="center" width="100%" pt="s" pb="s">
          <Text as="h3" weight="semiBold" fontsize="s" isUppercase>
            Ya soy cliente
          </Text>
          <Block width="100%" display="flex" justify="space-between" pt="l">
            <Button
              text="Iniciar sesion"
              onClick={() => history.push(routes.LOGIN, { from: history.location.pathname })}
              variant="default"
              color="xLightSilver"
              size="m"
              fullWidth
              id="sign_in_checkout"
            />
          </Block>
        </Block>
      </Block>
    </FormCard>
  );
};

export default CustomerData;
