import React from 'react';
import { Block, Button, Anchor } from '..';
import { IconSize } from '../Icon';
import { ShareWithProps } from './ShareWith.models';
import { useHistory } from 'react-router';
import links from './links';

const ShareWith: React.FC<ShareWithProps> = ({ url, size, icon, message, shape = 'circle' }) => {
  const renderButton = () => {
    switch (icon) {
      case 'facebook':
        return (
          <Anchor href={links.facebook(url)}>
            <Button variant="icon" icon="facebook" shape={shape} iconColor="facebook" />
          </Anchor>
        );
      case 'twitter':
        return (
          <Anchor href={links.twitter(url, message)}>
            <Button variant="icon" icon="twitter" shape={shape} iconColor="twitter" />
          </Anchor>
        );
      case 'whatsapp':
        return (
          <Anchor href={links.whatsapp(url, message)}>
            <Button variant="icon" icon="whatsapp" shape={shape} iconColor="whatsapp" />
          </Anchor>
        );
      case 'google':
        return (
          <Anchor href={links.google(url)}>
            <Button variant="icon" icon="google" shape={shape} />
          </Anchor>
        );
      case 'mail':
        return (
          <Anchor href={links.mail(url)}>
            <Button variant="icon" icon="mail" shape={shape} />
          </Anchor>
        );
    }
  };
  return <Block>{renderButton()}</Block>;
};

export default ShareWith;
