import React from 'react';
import { ListContainerProps } from './ListContainer.models';
import { Wrapper } from './ListContainer.styles';

export const ListContainer: React.FC<ListContainerProps> = (props) => (
  <Wrapper as={props.as ?? 'ul'} listStyle={props.listStyle ?? 'none'} {...props}>
    {props.children}
  </Wrapper>
);

export default ListContainer;
