import { Switch, Route } from 'react-router-dom';
import ErrorBoundary from '../../components/errorBoundary/ErrorBoundary';
import { routes } from '../../routes';
import ProductCategoryPage from './products/category/ProductCategoryPage';
import ProductDetailPage from './products/detail/ProductDetailPage';
import StoreDepartmentsPage from './departments/StoreDepartmentsPage';

const StoreRoutes = () => {
  return (
    <ErrorBoundary>
      <Switch>
        <Route exact path={`${routes.STORE_PRODUCTS}/:category/:name`}>
          <ProductDetailPage />
        </Route>
        <Route exact path={`${routes.STORE_PRODUCTS}/:category`}>
          <ProductCategoryPage />
        </Route>
        <Route exact path={`${routes.STORE_DEPARTMENTS}`}>
          <StoreDepartmentsPage />
        </Route>
        <Route exact path={`${routes.STORE_DEPARTMENTS}/:department`}>
          <StoreDepartmentsPage />
        </Route>
      </Switch>
    </ErrorBoundary>
  );
};

export default StoreRoutes;
