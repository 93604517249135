import type { IHttpClient } from '../../AxiosApiClient';
import { RegisterClientToNewsletterRequestDTO } from './newsletter.dto';

export interface INewsLetterRepository {
  registerClient(data: RegisterClientToNewsletterRequestDTO): Promise<void>;
}

export class NewsLetterRepository implements INewsLetterRepository {
  private endpoint: string = '/newsletter';

  constructor(private httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  async registerClient(data: RegisterClientToNewsletterRequestDTO): Promise<void> {
    return this.httpClient.post<RegisterClientToNewsletterRequestDTO, void>(`${this.endpoint}`, data);
  }
}
