import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { Block, Button, FormCard, Text } from '../../../../components';
import { useSelector } from 'react-redux';
import { DefaultState } from '../../../../redux/store';
import { CheckoutState } from '../../../../redux/checkout/checkout.slice';
import { selectCheckoutState } from '../../../../redux/checkout/checkout.selectors';
import classes from './StripeContainer.module.scss';
import { NumberHelper } from '../../../../helpers';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      color: 'rgb(0, 0, 0)',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: 'rgb(0, 0, 0)',
      },
      '::placeholder': {
        color: '#C7C7C7',
      },
    },
    invalid: {
      iconColor: 'rgb(168, 19, 19)',
      color: 'rgb(168, 19, 19)',
    },
  },
} as StripeCardElementOptions;

export interface StripeProps {
  disabled?: boolean;
  handleSteps: (step: number) => void;
  currentStep: number;
}

const StripeForm: React.FC<StripeProps> = ({ disabled, handleSteps, currentStep }) => {
  const { subTotal, discount, total, deliveryOption } = useSelector<DefaultState, CheckoutState>(selectCheckoutState);
  const deliveryAmount = deliveryOption.amount;
  return (
    <FormCard mb="m">
      <Block display="flex" direction="column">
        <Block display="flex" width="100%" justify="center" pt="s" pb="s">
          <Text as="h3" weight="semiBold" fontsize="s" isUppercase>
            Datos de la tarjeta
          </Text>
        </Block>
        <Block display="flex" direction="column">
          <Block display="flex" justify="space-between" align="center" pt="s" pb="s">
            <Text as="h4" fontsize="s">
              Subtotal:
            </Text>
            <Text fontsize="m" weight="medium">
              {NumberHelper.formatAmount(subTotal)}€
            </Text>
          </Block>
          <Block display="flex" justify="space-between" align="center" pt="s" pb="s">
            <Text as="h4" fontsize="s">
              Descuento:
            </Text>
            <Text fontsize="m" weight="medium">
              {NumberHelper.formatAmount(discount)}€
            </Text>
          </Block>
          <Block display="flex" justify="space-between" align="center" pt="s" pb="s">
            <Text as="h4" fontsize="s">
              Envío:
            </Text>
            {deliveryAmount > 0 ? (
              <Text fontsize="m" weight="medium">
                {NumberHelper.formatAmount(deliveryAmount)}€
              </Text>
            ) : (
              <Text fontsize="m" weight="medium">
                Gratis
              </Text>
            )}
          </Block>
          <Block display="flex" justify="space-between" align="center" pt="s" pb="s">
            <Text as="h4" fontsize="s">
              Total:
            </Text>
            <Text fontsize="m" weight="medium">
              {NumberHelper.formatAmount(total)}€
            </Text>
          </Block>
        </Block>
        <CardElement className={classes.stripe__input} options={CARD_OPTIONS} />
        <Block display="flex" justify="space-between" mt="m" pt="s" pb="s">
          <Button
            text="Volver"
            onClick={() => handleSteps(currentStep - 1)}
            variant="outlined"
            size="m"
            color="earth"
          />
          <Button text="Pagar" type="submit" disabled={disabled} variant="outlined" color="earth" size="m" />
        </Block>
      </Block>
    </FormCard>
  );
};

export default StripeForm;
