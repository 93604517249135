import styled, { css } from 'styled-components';

const buttonStyles = css`
  height: 100%;
  display: flex;
  min-width: 5%;
  background-color: rgba(0, 0, 0, 0.05);
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;
export const PrevButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: transparent;
  border: none;
`;

export const NextButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: transparent;
  border: none;
`;
