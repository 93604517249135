import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchAction, DefaultState } from '../../redux/store';
import Block from '../Block';
import HeaderLogo from './HeaderLogo';
import UserModal from '../UserModal/UserModal';
import Badge from '../Badge';
import Text from '../Text';
import { Button, Form, IconInput } from '..';
import { UserState } from '../../redux/user/user.slice';
import { selectUserState } from '../../redux/user/user.selectors';
import { logOut } from '../../redux/user/user.thunks';
import { FormikValues } from 'formik';
import { useCart } from '../../hooks';

interface Props {
  sideDrawerHandler: () => void;
  visibleSearchInputHandler: () => void;
  isVisibleSearchInput: boolean;
  closeSearchInputHandler: () => void;
  formik: FormikValues;
  isMobile: boolean;
}

const Toolbar: React.FC<Props> = function ({
  sideDrawerHandler,
  visibleSearchInputHandler,
  isVisibleSearchInput,
  closeSearchInputHandler,
  formik,
  isMobile,
}) {
  const [isVisibleUserModal, setIsVisibleUserModal] = useState(false);
  const dispatch = useDispatch<DispatchAction>();
  const { cart, getCartLength } = useCart();
  const { user } = useSelector<DefaultState, UserState>(selectUserState);
  const [cartLength, setCartLength] = useState<number>(0);
  const closeUserModalHandler = () => setIsVisibleUserModal(false);
  const singOut = () => {
    dispatch(logOut());
    closeUserModalHandler();
  };

  useEffect(() => {
    setCartLength(getCartLength());
  }, [cart]);

  return (
    <>
      <UserModal isOpen={isVisibleUserModal} onClose={closeUserModalHandler} user={user} singOut={singOut} />
      <Block
        width="100%"
        height="100%"
        display="flex"
        justify="space-between"
        align="center"
        bgColor="white"
        pt="m"
        pr="m"
        pb="m"
        pl="m"
        customStyles={{ maxHeight: '100px' }}
      >
        <Block display="flex" width="20%" onClick={sideDrawerHandler}>
          <Button
            onClick={() => null}
            type="button"
            variant="icon"
            iconSize="s"
            icon="menuBars"
            iconColor="black"
            id={`header_menu_btn`}
          />
        </Block>
        <HeaderLogo />
        <Block display="flex" width="30%" justify="flex-end" align="center">
          <Block display="flex" align="flex-end" justify="center" mr="s">
            {isVisibleSearchInput && !isMobile ? (
              <Form onSubmit={formik.handleSubmit} display="flex" width="100%">
                <IconInput
                  value={formik.values.search}
                  name="search"
                  type="text"
                  icon="closeX"
                  onChange={formik.handleChange}
                  shape="rounded"
                  placeholder="Escribe aqui"
                  bgColor="white"
                  onClick={closeSearchInputHandler}
                />
              </Form>
            ) : (
              <Button
                onClick={visibleSearchInputHandler}
                type="button"
                variant="icon"
                iconSize="s"
                icon="search"
                iconColor="black"
                id={`header_search_btn`}
                mr="m"
              />
            )}
          </Block>
          <Block display="flex" align="flex-end" justify="center" onClick={() => setIsVisibleUserModal(true)}>
            <Badge size="s" value={`✓`} isVisible={!!user?.isConfirmed} bgColor="green">
              <Button
                onClick={() => null}
                type="button"
                variant="icon"
                iconSize="s"
                icon="account"
                iconColor="black"
                id={`header_account_btn`}
              />
            </Badge>
          </Block>
          <Block display="flex" align="flex-end" justify="center" position="relative" pr="m">
            <Badge size="s" value={cartLength} isVisible={cartLength > 0}>
              <Link to="/cesta">
                <Button
                  onClick={() => null}
                  type="button"
                  variant="icon"
                  iconSize="s"
                  icon="basket"
                  iconColor="black"
                  id={`header_cart_btn`}
                  ml="m"
                />
              </Link>
            </Badge>
          </Block>
        </Block>
      </Block>
    </>
  );
};

export default Toolbar;
