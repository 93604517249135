import React from 'react';
import Backdrop from '../../../../../../components/Backdrop';
import ImgSlider from '../../../../../../components/imgSlider/ImgSlider';
import { ProductImage } from '../../../../../../domain/product/product.model';
import { Wrapper } from './ProductDetailLightBox.styles';

interface Props {
  data: Array<ProductImage>;
  selectedIndex: number;
  onClose: () => void;
}

const ProductDetailLightBox: React.FC<Props> = ({ data, selectedIndex, onClose }) => {
  const [show, setShow] = React.useState<boolean>(true);
  const closeHandler = () => {
    onClose();
    setShow(false);
  };
  return (
    <>
      <Backdrop isVisible={show} hasButton onClose={closeHandler} />
      <Wrapper>
        <ImgSlider selectedIndex={selectedIndex} data={data} />
      </Wrapper>
    </>
  );
};

export default ProductDetailLightBox;
