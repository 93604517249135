import { IHttpClient } from '../../AxiosApiClient';
import { IRepository } from '../base.repository';
import { Slider } from './slider.model';

export interface ISliderRepository extends IRepository {
  getMany(): Promise<Array<Slider>>;
}

export class SliderRepository implements ISliderRepository {
  private httpClient: IHttpClient;
  private endpoint: string = '/slider';

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  async get<Slider>(id: string): Promise<Slider> {
    return this.httpClient.get<Slider>(this.endpoint);
  }

  async getMany(): Promise<Array<Slider>> {
    return this.httpClient.get<Array<Slider>>(this.endpoint);
  }
}
