import React from 'react';
import { HeaderContainer } from './Header.styles';
import TopBar from './TopBar';
import Toolbar from './ToolBar';
import IconInput from '../IconInput';
import { FormikValues } from 'formik';
import Form from '../Form';

interface Props {
  sideDrawerHandler: () => void;
  visibleSearchInputHandler: () => void;
  isVisibleSearchInput: boolean;
  formik: FormikValues;
  isMobile: boolean;
  closeSearchInputHandler: () => void;
}

const Header: React.FC<Props> = ({
  sideDrawerHandler,
  visibleSearchInputHandler,
  isVisibleSearchInput,
  formik,
  isMobile,
  closeSearchInputHandler,
}) => (
  <HeaderContainer id="header">
    <TopBar />
    <Toolbar
      closeSearchInputHandler={closeSearchInputHandler}
      sideDrawerHandler={sideDrawerHandler}
      visibleSearchInputHandler={visibleSearchInputHandler}
      isVisibleSearchInput={isVisibleSearchInput}
      formik={formik}
      isMobile={isMobile}
    />
    {isVisibleSearchInput && isMobile && (
      <Form onSubmit={formik.handleSubmit} display="flex" width="100%">
        <IconInput
          value={formik.values.search}
          name="search"
          type="text"
          icon="closeX"
          onChange={formik.handleChange}
          shape="square"
          placeholder="Escribe aqui"
          bgColor="white"
          onClick={closeSearchInputHandler}
        />
      </Form>
    )}
  </HeaderContainer>
);

export default Header;
