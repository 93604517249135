import React, { useState, useEffect, Suspense, useCallback, useContext } from 'react';
import { Block, CookiesConsentModal, Footer, Header, MarketingModal } from './components';
import { useDispatch, useSelector } from 'react-redux';
import SnackbarComponent from './components/SnackbarComponent';
import snackbarActions from './redux/snackbar/snackbar.actions';
import SideDrawer from './components/SideDrawer';
import { Spinner } from './components';
import './styles/style.scss';
import { DispatchAction, DefaultState } from './redux/store';
import { ISnackbarState } from './redux/snackbar/snackbar.reducer';
import { Product } from './domain/product/product.model';
import Backdrop from './components/Backdrop/Backdrop';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { refreshJwt } from './redux/user/user.thunks';
import AppRoutes from './AppRoutes';
import { ServicesContext } from './contexts/ServicesContext';
import { routes } from './routes';
import { useLocation } from 'react-router';
import { SearchResults } from './pages';
import { useFormik } from 'formik';
import { getByCriteria } from './redux/products/products.thunks';
import { haveAcceptedCookies } from './components/CookiesConsentModal';
import { StorageHandlerHelper, StringHelper } from './helpers';
import { getCart } from './redux/cart/cart.thunks';

export interface SearchFormikState {
  search: string;
}

const App = () => {
  const { productService, sliderService } = useContext(ServicesContext)!;
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const sideDrawerHandler = () => setIsOpenDrawer(!isOpenDrawer);
  const [mosaicData, setMosaicData] = useState<Array<Product>>([]);
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const [isVisibleMarketingModal, setIsVisibleMarketingModal] = useState<boolean>(false);
  const dispatch = useDispatch<DispatchAction>();
  const { openSnackbar, snackbarSuccess, snackbarMessage } = useSelector<DefaultState, ISnackbarState>(
    (state) => state.snackbar,
  );
  const { t } = useTranslation('home');
  const location = useLocation();
  const acceptedCookies = haveAcceptedCookies();
  const [showSearchResultsPage, setShowSearchResultsPage] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<{ products: Array<Product>; count: number }>({
    products: [],
    count: 0,
  });
  const [isVisibleSearchInput, setIsVisibleSearchInput] = useState<boolean>(false);
  const [searchedProductsPage, setSearchedProductsPage] = useState(1);
  const [visibleSearchedProductsPerPage] = useState(10);
  const [showPages, setShowPages] = useState(true);

  const onCloseCookiesModal = () => {
    setIsVisibleModal(false);
    setTimeout(() => {
      setIsVisibleMarketingModal(true);
    }, 2000);
  };
  const visibleMarketingModalHandler = () => {
    setIsVisibleMarketingModal(false);
  };

  useEffect(() => {
    (async () => {
      const mosaic = await productService.getMosaicItems();
      setMosaicData(mosaic);
    })();
    return () => {};
  }, []);

  useEffect(() => {
    const urlToken = StringHelper.getValueFromUrl('token')
    if (urlToken) {
      StorageHandlerHelper.save('token', urlToken);
      history.replaceState({}, document.title, window.location.pathname);
    }
  }, [])

  useEffect(() => {
    const tokenOrNull = StorageHandlerHelper.retrieve('token');
    if (tokenOrNull) {
      dispatch(refreshJwt({}));
    }
    dispatch(getCart());
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    let timer;
    if (!acceptedCookies) {
      setIsVisibleModal(true);
    } else if (!isVisibleModal && location.pathname === routes.BASE) {
      timer = setTimeout(() => {
        setIsVisibleMarketingModal(true);
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [acceptedCookies]);

  const closeSnackbar = useCallback(() => {
    dispatch(snackbarActions.clear());
  }, [dispatch]);

  const initialState: SearchFormikState = {
    search: '',
  };

  const formik = useFormik({
    initialValues: initialState,
    onSubmit: async (values) => {
      const { products, count } = await getByCriteria({
        field: 'name',
        value: values.search,
        operator: 'LIKE',
        page: searchedProductsPage,
        limit: visibleSearchedProductsPerPage,
      });
      if (products && products.length > 0) {
        setSearchResults({
          products: searchResults.products.concat(products),
          count,
        });
      }
      setShowPages(false);
      setShowSearchResultsPage(true);
    },
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (formik.values.search) {
        formik.handleSubmit();
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [formik.values]);

  const closeSearchInputHandler = () => {
    formik.resetForm();
    setShowSearchResultsPage(false);
    setIsVisibleSearchInput(false);
    setShowPages(true);
    setSearchResults({
      products: [],
      count: 0,
    });
  };
  const visibleSearchInputHandler = () => setIsVisibleSearchInput(true);

  const showMoreHandler = () => {
    setSearchedProductsPage((prev) => prev + 1);
    formik.handleSubmit();
  };
  const showMoreButton: boolean = searchResults?.count > 0 && searchResults?.count > searchResults?.products.length;
  if (!mosaicData) return <Spinner />;
  const isMobile = window.innerWidth <= 800;

  return (
    <Suspense fallback={<Spinner />}>
      <MarketingModal isOpen={isVisibleMarketingModal} onClose={visibleMarketingModalHandler} />
      <SnackbarComponent
        open={openSnackbar}
        success={snackbarSuccess}
        text={snackbarMessage}
        close={closeSnackbar}
        autoHideDuration={4000}
      />
      <Helmet>
        <title>{t('meta.title') ?? ''}</title>
        <meta name="description" content={t('meta.description') ?? ''} />
        <meta name="keywords" content={t('meta.keywords') ?? ''} />
      </Helmet>
      <SideDrawer isOpen={isOpenDrawer} onClose={sideDrawerHandler} />
      <Backdrop isVisible={isOpenDrawer} hasButton onClose={sideDrawerHandler} />
      <CookiesConsentModal isOpen={isVisibleModal} onClose={onCloseCookiesModal} />
      <Block display="flex" direction="column" customStyles={{ flex: '1', minHeight: '100vh' }}>
        <Header
          sideDrawerHandler={sideDrawerHandler}
          closeSearchInputHandler={closeSearchInputHandler}
          visibleSearchInputHandler={visibleSearchInputHandler}
          isVisibleSearchInput={isVisibleSearchInput}
          formik={formik}
          isMobile={isMobile}
        />
        {!formik.isSubmitting && (
          <SearchResults
            isOpen={showSearchResultsPage}
            products={searchResults?.products}
            isMobile={isMobile}
            onClose={closeSearchInputHandler}
            showMoreHandler={showMoreHandler}
            showMoreButton={showMoreButton}
          />
        )}
        <Block
          customStyles={{
            flex: '1',
            marginTop: '130px',
            display: `${showPages ? 'block' : 'none'}`,
          }}
        >
          <AppRoutes mosaicData={mosaicData} />
        </Block>
        <Footer />
      </Block>
    </Suspense>
  );
};

export default App;
