import styled, { css } from 'styled-components';
import { margin } from '../../theme';

import { IconSize, IconContainerProps } from './Icon.models';

const getSize = (size: IconSize) => {
  switch (size) {
    case 'xs':
      return css`
        width: 0.938rem;
        height: 0.938rem;
      `;
    case 'm':
      return css`
        width: 1.563rem;
        height: 1.563rem;
      `;
    case 'l':
      return css`
        width: 2.563rem;
        height: 2.563rem;
      `;
    default:
      return css`
        width: 1.4rem;
        height: 1.4rem;
      `;
  }
};

const setStyles = (color: string, isSorteable?: boolean) => {
  // if (isSorteable) {
  //   return css`
  //     > svg {
  //       fill: ${p => p.theme.color[color]};
  //       &:hover {
  //         fill: ${p => p.theme.color[color]}90;
  //       }
  //       /* &:active {
  //         fill: ${(p) => p.theme.color.white};
  //       } */
  //     }
  //     `;
  // }
  return css`
    > svg {
      fill: ${(p) => p.theme.color[color]};
    }
    &:hover {
      color: ${(p) => p.theme.color[color]}90;
      fill: ${(p) => p.theme.color[color]}90;
    }
  `;
};

export const Container = styled.div<IconContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(p) => p.cursor && p.cursor};
  ${margin};
  > svg {
    ${(p) => getSize(p.size)};
    fill: ${(p) => p.theme.color[p.color]};
    &:hover {
      color: ${(p) => p.theme.color[p.color]}90;
      fill: ${(p) => p.theme.color[p.color]}90;
    }
    ${(p) => p.customStyles && p.customStyles}
  }
`;
