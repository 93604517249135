import { useState, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { DispatchAction, DefaultState } from '../../redux/store';
import { StripeCardElement } from '@stripe/stripe-js';
import { CheckoutProduct, DeliveryOptionSlug } from '../../domain/checkout/checkout.model';
import CheckoutLayout from './CheckoutLayout';
import { SoldOutProductModal } from '../../components';
import ProcessPaymentModal from './components/ProcessPaymentModal';
import { UserState } from '../../redux/user/user.slice';
import { selectUserState } from '../../redux/user/user.selectors';
import { selectCheckoutState } from '../../redux/checkout/checkout.selectors';
import { CheckoutState } from '../../redux/checkout/checkout.slice';
import { setInitialCheckoutStateAction } from '../../redux/checkout/checkout.thunks';
import { useCart, useStepper } from '../../hooks';
import { ProcessPaymentRequestDTO } from '../../domain/payment/payment.dtos';
import { ServicesContext } from '../../contexts/ServicesContext';
import { routes } from '../../routes';
import { getSoldOutProducts } from '../../redux/products/products.thunks';
import { StorageHandlerHelper } from '../../helpers';

export interface CheckoutFormikState {
  address: string;
  zipCode: string;
  city: string;
  country: string;
  phone: string;
  pickUp: boolean;
  delivery: DeliveryOptionSlug;
  guessName: string;
  guessLastName: string;
  guessEmail: string;
  acceptNotifications: boolean;
}

const CheckoutPage = () => {
  const { paymentService } = useContext(ServicesContext)!;
  const history = useHistory<any>();
  const stripe = useStripe();
  const elements = useElements();
  const [modal, setModal] = useState<{
    open: boolean;
    title?: string;
    text?: string;
  }>({
    open: false,
    title: '',
    text: '',
  });
  const [soldOutModal, setSoldOutModal] = useState<{
    open: boolean;
    title: string;
    text: string;
    data: Array<string>;
  }>({
    open: false,
    title: 'Productos agotados',
    text: 'Por favor eliminalos para poder continuar',
    data: [],
  });
  const [isSuccessPayment, setIsSuccessPayment] = useState<boolean>(false);
  const { user } = useSelector<DefaultState, UserState>(selectUserState);
  const { subTotal, discount, total, products, cartId, deliveryInfo, deliveryOption, guessData, orderId } = useSelector<
    DefaultState,
    CheckoutState
  >(selectCheckoutState);
  const dispatch = useDispatch<DispatchAction>();
  const { clearCart, cart } = useCart();

  const handleCloseSoldOutModal = () => setSoldOutModal({ ...soldOutModal, open: false });
  const steps = ['Identificate', 'Datos de envio', 'Opciones de envio', 'Pago'];
  const { currentStep, goToStep } = useStepper(0, steps);

  const handleSteps = (indx: number) => {
    if (user?.isConfirmed && indx === 0) {
      return;
    }
    goToStep(indx);
  };

  useEffect(() => {
    dispatch(setInitialCheckoutStateAction());
    if (user?.isConfirmed) {
      goToStep(1);
    }
    return () => {};
  }, [user, cart]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === 1 && !cart?.items.length) {
      console.log('No hay items en el carro')
    }
    return () => {};
  }, [currentStep]);

  const soldOutProductsTitles = (
    products: Array<CheckoutProduct>,
    soldOutProductsIds: Array<{ id: string; stock: number }>,
  ) => {
    const unAvailableTitles: Array<string> = [];
    const unAvailableIds: Array<string> = [];
    products.forEach((product) => {
      soldOutProductsIds.forEach((item) => {
        if (product.id === item.id) {
          unAvailableTitles.push(product.name);
          unAvailableIds.push(product.id);
        }
      });
    });
    return {
      titles: unAvailableTitles,
      ids: unAvailableIds,
    };
  };

  const handleClose = () => {
    if (isSuccessPayment) {
      history.push(routes.BASE, { successPayment: true });
    }
    setModal({ open: false });
  };

  const initialState: CheckoutFormikState = {
    address: deliveryInfo?.address ?? '',
    zipCode: deliveryInfo?.zipCode ?? '',
    city: deliveryInfo?.city ?? '',
    country: deliveryInfo?.country ?? '',
    phone: deliveryInfo.phone ?? '',
    pickUp: false,
    delivery: deliveryOption?.type,
    guessName: guessData?.name ?? '',
    guessLastName: guessData?.lastName ?? '',
    guessEmail: guessData?.email ?? '',
    acceptNotifications: guessData?.acceptNotifications ?? false,
  };

  const formik = useFormik({
    initialValues: initialState,
    onSubmit: async () => {
      if (!stripe || !elements) {
        return;
      }
      try {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement) as StripeCardElement,
          billing_details: {
            name: user?.name ?? guessData?.name,
            email: user?.email ?? guessData?.email,
          },
        });
        if (!error && formik.dirty && orderId) {
          const productsToCheck = products?.map((product, i) => ({
            id: product.id,
            quantity: product.quantity,
          }));

          const soldOutProducts = await getSoldOutProducts(productsToCheck);
          if (soldOutProducts?.length > 0) {
            const { titles } = soldOutProductsTitles(products, soldOutProducts);
            setSoldOutModal({
              ...soldOutModal,
              open: true,
              data: titles,
            });
          } else {
            try {
              setModal({
                open: true,
                title: 'Procesando pago',
                text: 'Favor no cerrar ni reiniciar la pagina.',
              });
              const { id } = paymentMethod;
              const paymentClientId = id;
              const paymentRequest: ProcessPaymentRequestDTO = {
                orderId,
                paymentClientId,
                paymentMethod: 'CARD',
                currency: 'EUR',
              };

              await paymentService.processPayment(paymentRequest);
              setIsSuccessPayment(true);
              if (cartId) {
                StorageHandlerHelper.clear('cart_items');
                clearCart();
              }
              setModal({
                open: true,
                title: 'Operacion exitosa',
                text: 'Revise su correo electronico',
              });
            } catch (error) {
              elements.getElement('card')?.focus();
              setModal({
                open: true,
                title: 'Operacion fallida',
                text: 'Intente nuevamente, si el problema persiste contacte con nosotros.',
              });
            }
          }
        } else {
          setModal({
            open: true,
            title: 'Lo sentimos',
            text: 'En estos momentos no podemos procesar su solicitud',
          });
        }
      } catch (error) {
        console.error('ERROR', error);
      }
    },
    validate: (values: CheckoutFormikState) => {
      const errors: any = {};
      for (const val in values) {
        const guessKeys = ['guessName', 'guessLastName', 'guessEmail', 'acceptNotifications'];
        const isGuessValue = guessKeys.includes(val);
        if (!user?.isConfirmed) {
          if (values[val as keyof CheckoutFormikState] === '') {
            errors[val as keyof CheckoutFormikState] = 'Campo requerido';
          }
        } else if (!isGuessValue) {
          if (values[val as keyof CheckoutFormikState] === '') {
            errors[val as keyof CheckoutFormikState] = 'Campo requerido';
          }
        }
      }
      return errors;
    },
  });

  const showDeliveryInput = subTotal < 40;
  const isButtonDisabled =
    !stripe ||
    !formik.dirty ||
    formik.isSubmitting ||
    (showDeliveryInput && !formik.values.delivery) ||
    soldOutModal?.data?.length > 0;
  return (
    <>
      <SoldOutProductModal
        onClose={handleCloseSoldOutModal}
        isOpen={soldOutModal.open}
        title={soldOutModal.title}
        text={soldOutModal.text}
        soldOutProductNames={soldOutModal?.data}
      />
      <ProcessPaymentModal
        onClose={handleClose}
        isOpen={modal.open}
        title={modal.title}
        text={modal.text}
        isSubmitting={formik.isSubmitting}
      />
      <CheckoutLayout
        formik={formik}
        showDeliveryInput={showDeliveryInput}
        isButtonDisabled={isButtonDisabled}
        discount={discount}
        subTotal={subTotal}
        totalToPay={total}
        steps={steps}
        currentStep={currentStep}
        handleSteps={handleSteps}
      />
    </>
  );
};

export default CheckoutPage;
