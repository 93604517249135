import container from '../../container';
import { ProductService } from '../../domain/product/product.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetProductsResponseDTO } from '../../domain/product/products.dtos';
import { Criteria } from '../../domain/product/product.repository';
import { Product } from '../../domain/product/product.model';

const productService = container.get<ProductService>('ProductService');
export const getSoldOutProducts = async (productsToCheck: Array<{ id: string; quantity: number }>) => {
  return productService.checkAvailability({ products: productsToCheck });
};

export const getProducts = createAsyncThunk<
  GetProductsResponseDTO['products'],
  { category: string },
  { rejectValue: string }
>('products/get', async ({ category }, thunkApi) => {
  try {
    const products = await productService.getByCategory(category);
    return products;
  } catch (error) {
    return thunkApi.rejectWithValue((error as Error).message);
  }
});

export const getByCriteria = async (criteria: Criteria): Promise<GetProductsResponseDTO> => {
  return productService.getByCriteria({
    field: criteria?.field,
    value: criteria?.value,
    operator: criteria?.operator ?? 'EQUAL',
    limit: criteria?.limit ?? 1,
  });
};

export const getById = async (id: string) => productService.getById(id);

export const getRelatedProducts = async (ids: Array<string>): Promise<Array<Product>> => {
  const promises = ids.map(async (id) => {
    try {
      return await getById(id);
    } catch (error) {
      return null; 
    }
  });

  const products = await Promise.all(promises);

  return products.filter((product) => product !== null) as Product[];
};