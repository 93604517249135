/* eslint-disable no-unused-vars */
import React from 'react';
import { ButtonWrapper } from './Button.styles';
import Icon from '../Icon';
import { ButtonProps } from './Button.models';

const Button: React.FC<ButtonProps> = (props) => (
  <ButtonWrapper
    {...props}
    type={props.type ?? 'button'}
    id={props.id ?? 'button_id'}
    size={props.size ?? 'm'}
    isUppercase={props.isUppercase ?? false}
  >
    {props.position === 'start' ? (
      <>
        {props.text && props.text}
        {props.icon && (
          <Icon
            ml={props.text ? 's' : undefined}
            isSortable={props.isSortableIcon}
            customStyles={props.iconCustomStyles}
            color={props.iconColor ?? 'black'}
            icon={props.icon}
            size={props.iconSize ?? 's'}
          />
        )}
      </>
    ) : (
      <>
        {props.icon && (
          <Icon
            mr={props.text ? 's' : undefined}
            isSortable={props.isSortableIcon}
            customStyles={props.iconCustomStyles}
            color={props.iconColor ?? 'black'}
            icon={props.icon}
            size={props.iconSize ?? 's'}
          />
        )}
        {props.text && props.text}
      </>
    )}
  </ButtonWrapper>
);

export default Button;
