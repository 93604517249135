import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Block, Form, FormCard, Input, Spinner, Button } from '../../../components';
import { DispatchAction, DefaultState } from '../../../redux/store';
import { recoveryPassword } from '../../../redux/user/user.thunks';
import { UserState } from '../../../redux/user/user.slice';
import { selectUserState } from '../../../redux/user/user.selectors';
import { isValidEmail } from '../../../modules/user/domain/User';

export interface ForgotPasswordFormikState {
  email: string;
}

const ForgotPasswordPage = function () {
  const { isLoading } = useSelector<DefaultState, UserState>(selectUserState);
  const dispatch = useDispatch<DispatchAction>();
  const initialState: ForgotPasswordFormikState = {
    email: '',
  };

  const formik = useFormik({
    initialValues: initialState,
    onSubmit: async (values) => {
      dispatch(recoveryPassword({ userData: { email: values.email }, formik }));
    },
    validate: (values: ForgotPasswordFormikState) => {
      const errors: Partial<ForgotPasswordFormikState> = {};
      if (!values.email) {
        errors.email = 'Email requerido';
      } else if (!isValidEmail(values.email)) {
        errors.email = 'Email invalido';
      }
      return errors;
    },
  });

  if (formik.isSubmitting || isLoading) {
    return <Spinner top="25%" />;
  }

  const isButtonDisabled = !formik.dirty || formik.isSubmitting || !formik.isValid;

  return (
    <Form
      onSubmit={formik.handleSubmit}
      display="flex"
      direction="column"
      justify="space-evenly"
      align="center"
      width="100%"
      pt="xl"
      pb="xl"
    >
      <FormCard>
        <Block width="100%" display="flex" direction="column" align="center" justify="center">
          <Block display="flex" width="100%" justify="center" pt="s" pb="s">
            <Text as="h3" weight="semiBold" fontsize="s" isUppercase>
              Olvido de contraseña
            </Text>
          </Block>
          <Block display="flex" direction="column" width="100%" pt="s" pb="s">
            <Text as="h4" fontsize="s">
              Email
            </Text>
            <Input
              type="text"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              variant="outlined"
              inputSize="m"
            />
            {formik.errors.email && (
              <Block display="flex" width="100%" justify="center">
                <Text fontsize="s" color="red">
                  {formik.errors.email}
                </Text>
              </Block>
            )}
          </Block>
          <Block display="flex" justify="center" mt="m" pt="s" pb="s" width="100%">
            <Button
              text="Enviar"
              type="submit"
              disabled={isButtonDisabled}
              variant="default"
              color="xLightSilver"
              size="m"
              fullWidth
              id="forgot_password_send"
            />
          </Block>
        </Block>
      </FormCard>
    </Form>
  );
};

export default ForgotPasswordPage;
