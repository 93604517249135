import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../../../../redux/products/products.thunks';
import { PageContainer, ProductCard, Spinner } from '../../../../components';
import { DispatchAction, DefaultState } from '../../../../redux/store';
import { Block, Text } from '../../../../components';
import { ProductState } from '../../../../redux/products/products.slice';
import { selectProductsState } from '../../../../redux/products/products.selectors';

const getTitle = (param: string) => {
  if (param.includes('-')) {
    return param.replace(/-/gi, ' ');
  }
  if (param === 'mala') {
    return 'Malas';
  }
  if (param === 'ninos') {
    return 'Niños';
  }
  return param;
};

const ProductCategoryPage = () => {
  const { category } = useParams<{ category: string }>();
  const { products, isLoading } = useSelector<DefaultState, ProductState>(selectProductsState);
  const dispatch = useDispatch<DispatchAction>();

  const title = getTitle(category);
  useEffect(() => {
    (async () => {
      dispatch(getProducts({ category }));
    })();
    window.scrollTo(0, 0);
    return () => {};
  }, [category, dispatch]);

  if (isLoading) {
    return <Spinner top="25%" />;
  }

  return (
    <PageContainer pageTitle={title}>
      <Block
        display="flex"
        flexWrap="wrap"
        justify={products.length > 0 ? 'space-between' : 'center'}
        align="center"
        pt="s"
        pr="l"
        pb="s"
        pl="l"
      >
        {products.length > 0 ? (
          products.map((item, index) => <ProductCard productData={item} category={category} key={index} />)
        ) : (
          <Text as="h2" color="earth" fontsize="s" fontFamily="title" mt="s" mb="s" textAlign="center">
            No hay productos que coincidan
          </Text>
        )}
      </Block>
    </PageContainer>
  );
};

export default ProductCategoryPage;
